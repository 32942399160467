import { TagField } from '@pankod/refine-antd';
import { FC } from 'react';

interface Props {
  value: boolean;
}

export const ShowBoolean: FC<Props> = ({ value }) => (
  <TagField value={value ? 'Yes' : 'No'} color={value ? 'success' : 'error'} />
);
