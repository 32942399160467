import { FC } from 'react';
import styles from './styles.module.css';

export const ShowColor: FC<{ value: string }> = ({ value }) => (
  <div
    title={value}
    className={styles['show-color']}
    style={{ backgroundColor: value }}
  />
);
