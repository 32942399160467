import { useState, FC } from 'react';

import {
  useLogout,
  useTitle,
  useNavigation,
  ITreeMenu,
  useRefineContext,
  useMenu,
} from '@pankod/refine-core';
import { AntdLayout, Menu, Grid, Icons } from '@pankod/refine-antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { antLayoutSider, antLayoutSiderMobile } from './styles';

const { LogoutOutlined, DashboardOutlined } = Icons;

function renderMenuItem(
  item: ITreeMenu,
  selectedKey: string,
  collapsed: boolean,
): ItemType {
  if (item.children.length) {
    return {
      key: item.route as string,
      label: item.label,
      type: 'group',
      children: item.children.map(child =>
        renderMenuItem(child, selectedKey, collapsed),
      ),
    };
  }

  const isSelected = item.route === selectedKey;

  return {
    key: item.route as string,
    label: item.label,
    style: {
      fontWeight: isSelected ? 'bold' : 'normal',
    },
    icon: item.icon,
  };
}

export const Sider: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const { menuItems, selectedKey } = useMenu();
  const { push } = useNavigation();
  const { hasDashboard } = useRefineContext();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  const items: ItemType[] = [
    ...menuItems.map(item => renderMenuItem(item, selectedKey, collapsed)),
    {
      type: 'group',
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];

  if (hasDashboard) {
    items.unshift({
      label: 'Dashboard',
      key: '/',
      icon: <DashboardOutlined />,
    });
  }

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => {
        setCollapsed(collapsed);
      }}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}

      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (key === 'logout') {
            logout();
            return;
          }

          if (!breakpoint.lg) {
            setCollapsed(true);
          }
          push(key);
        }}
        items={items}
      />
    </AntdLayout.Sider>
  );
};
