import { Form, InputNumber } from '@pankod/refine-antd';
import { FC } from 'react';
import { NestedContainer } from '../nested-container';
import styles from './styles.module.css';

interface Props {
  name?: string;
  disabled?: boolean;
  required?: boolean;
}

export const LocationFormInput: FC<Props> = ({
  name = 'location',
  required = true,
  disabled = false,
}) => {
  return (
    <NestedContainer>
      <Form.Item
        label="Latitude"
        name={[name, 'lat']}
        rules={[{ required }, { type: 'number', min: -90, max: 90 }]}
      >
        <InputNumber className={styles['input']} disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Longitude"
        name={[name, 'lng']}
        rules={[{ required }, { type: 'number', min: -180, max: 180 }]}
      >
        <InputNumber className={styles['input']} disabled={disabled} />
      </Form.Item>
    </NestedContainer>
  );
};
