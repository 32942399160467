import { FC } from 'react';

import { List, Table, Space, ShowButton, Row, Col } from '@pankod/refine-antd';

import {
  PaymentEnvironment,
  PaymentHandleStatus,
  PaymentProvider,
  PaymentStatus,
} from 'src/constants/enums';
import { PaymentDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';

export const PaymentList: FC = () => {
  const { tableProps, filterContext } = useTable<PaymentDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {idColumn({
              filterContext,
              field: 'userId',
              title: 'User ID',
              render: (_, record: PaymentDTO) => (
                <ReferenceOne
                  resource="users"
                  id={record.user.id}
                  label={record.user.username}
                />
              ),
            })}
            {enumColumn({
              filterContext,
              field: 'provider',
              enum: PaymentProvider,
            })}
            {enumColumn({
              filterContext,
              field: 'environment',
              enum: PaymentEnvironment,
            })}
            {enumColumn({
              filterContext,
              field: 'status',
              enum: PaymentStatus,
            })}
            {enumColumn({
              filterContext,
              field: 'handleStatus',
              enum: PaymentHandleStatus,
            })}
            {textColumn({
              filterContext,
              field: 'transactionOriginalId',
            })}
            {textColumn({
              filterContext,
              field: 'transactionId',
            })}
            {textColumn({
              filterContext,
              field: 'storeProductId',
            })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<PaymentDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
