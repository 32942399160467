import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, Empty } from '@pankod/refine-antd';

import { UserSubscriptionDTO } from 'src/dtos';
import { ShowItem } from 'src/components/show-item';
import { ReferenceOne } from 'src/components/reference-one';
import { ShowBoolean } from 'src/components/show-boolean';

export const UserSubscriptionShow: FC = () => {
  const { queryResult } = useShow<UserSubscriptionDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="User">
        {record?.user && (
          <ReferenceOne
            id={record.user.id}
            label={record.user.username}
            resource="users"
          />
        )}
      </ShowItem>

      <ShowItem title="Subscription">
        {record?.subscription === null && (
          <ReferenceOne
            id={record.subscriptionId}
            label="DELETED_PRODUCT"
            resource="products"
          />
        )}

        {record?.subscription && (
          <ReferenceOne
            id={record.subscription.id}
            label={record.subscription.title}
            resource="products"
          />
        )}
      </ShowItem>

      <ShowItem title="Transaction Original Id">
        {record?.transactionOriginalId ? (
          record.transactionOriginalId
        ) : (
          <Empty />
        )}
      </ShowItem>

      <ShowItem title="Active">
        {record && <ShowBoolean value={record.active} />}
      </ShowItem>

      <ShowItem title="Start Date">
        {record?.startDate ? (
          <DateField format="LLL" value={record?.startDate} />
        ) : (
          <Empty />
        )}
      </ShowItem>

      <ShowItem title="Is Test">
        {record && <ShowBoolean value={record?.isTest} />}
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
