import { FC } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  useForm,
  Divider,
  useSelect,
  Select,
  Edit,
} from '@pankod/refine-antd';

import { UserHeroDTO, UserDTO } from 'src/dtos';
import { useRole } from 'src/hooks/use-role';
import {
  MOBILE_PHONE_MESSAGE,
  MOBILE_PHONE_REGEX,
} from 'src/constants/validation';

export const UserHeroEdit: FC = () => {
  const { isSuperAdmin } = useRole();
  const { formProps, saveButtonProps } = useForm<UserHeroDTO>();
  const { selectProps } = useSelect<UserDTO>({
    resource: 'users',
    optionLabel: 'username',
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                {
                  required: true,
                },
                {
                  min: 5,
                },
                {
                  max: 255,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: MOBILE_PHONE_REGEX,
                  message: MOBILE_PHONE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              label="User"
              name="userId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...selectProps} disabled={!isSuperAdmin()} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
