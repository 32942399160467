import { notification } from '@pankod/refine-antd';
import { PickedImageValueDTO } from 'src/utils/image.utils';
import { api, isNotOk } from './http.service';

export const removePhotos = async (userId: string, ids: string[]) => {
  if (!ids.length) {
    return;
  }

  const searchParams = new URLSearchParams();

  for (const id of ids) {
    searchParams.append('ids', id);
  }

  const { status } = await api.delete(
    `users/${userId}/photos/bulk?${searchParams.toString()}`,
  );

  if (isNotOk(status)) {
    notification.error({ message: 'Error during photos remove' });
  }
};

export const uploadPhotos = async (
  userId: string,
  photos: PickedImageValueDTO[],
) => {
  if (!photos.length) {
    return;
  }

  const files = photos.map(({ data }) => data);

  const formData = new FormData();

  for (const file of files) {
    formData.append('files', file);
  }

  const { status } = await api.post(`users/${userId}/photos/bulk`, formData);

  if (isNotOk(status)) {
    return notification.error({ message: 'Error during photos upload' });
  }
};
