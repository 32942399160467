import { FC } from 'react';

import {
  List,
  Table,
  Space,
  ShowButton,
  Row,
  Col,
  DeleteButton,
  EditButton,
} from '@pankod/refine-antd';

import { AdminDTO } from 'src/dtos';
import { AdminRole } from 'src/constants/enums';
import { SetPasswordButton } from './set-password/set-password.button';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';
import { useRole } from 'src/hooks/use-role';

export const AdminList: FC = () => {
  const { tableProps, filterContext } = useTable<AdminDTO>();
  const { isSuperAdmin } = useRole();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List canCreate={isSuperAdmin()}>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'username' })}
            {enumColumn({ filterContext, field: 'role', enum: AdminRole })}
            {dateColumn({ filterContext, field: 'createdAt' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}

            <Table.Column<AdminDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {isSuperAdmin() && (
                    <SetPasswordButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                  {isSuperAdmin() && (
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {isSuperAdmin() && (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
