import { FC, useRef } from 'react';
import {
  Create,
  Empty,
  Form,
  Input,
  notification,
  RcFile,
  Select,
} from '@pankod/refine-antd';
import { isImageDTO, ProductDTO } from 'src/dtos';
import { ProductType, ProductPlatform } from 'src/constants/enums';
import { isHasMetadata, MetaCreate } from './meta/meta.create';
import { useForm } from 'src/hooks/use-form';
import { ColorInput } from 'src/components/color-input';
import { DetailsForm } from './details/details.form';
import { ImageInput } from 'src/components/image-input';
import { api, isNotOk } from 'src/services/http.service';

export const ProductCreate: FC = () => {
  const fileRef = useRef<RcFile>();

  const { formProps, form, saveButtonProps } = useForm<
    ProductDTO,
    ProductDTO,
    ProductDTO
  >({
    onFinish: values => {
      if (values.details?.length === 0) {
        values.details = null;
      }

      if (!isImageDTO(values.cover)) {
        const { cover, ...rest } = values;

        fileRef.current = cover;

        return { ...rest };
      }

      return values;
    },
    onMutationSuccess: async ({ data }) => {
      const coverFile = fileRef.current;
      const id = data.id;

      if (!coverFile || !id) return;

      const formData = new FormData();
      formData.append('file', coverFile);

      const { status } = await api.post(`products/${id}/cover`, formData);

      if (isNotOk(status)) {
        notification.error({ message: 'Error during cover uploading' });
        return;
      }

      notification.success({ message: 'Cover successfully uploaded' });
    },
  });

  const type: ProductType | undefined = Form.useWatch('type', form);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              type: 'string',
              max: 255,
              required: true,
            },
          ]}
          tooltip="locize key"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Details"
          name="details"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <DetailsForm />
        </Form.Item>

        <Form.Item
          label="Color"
          name="color"
          rules={[
            {
              type: 'string',
              max: 255,
              required: true,
            },
          ]}
          initialValue="#000000"
          tooltip="Title Background. No longer in use"
        >
          <ColorInput />
        </Form.Item>

        <Form.Item
          label="Store Product Id"
          name="storeProductId"
          tooltip="Should be unique and the same in google play and app store"
          rules={[
            {
              type: 'string',
              max: 1000,
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Cover"
          name="cover"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ImageInput />
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={ProductType.AD}>Ad</Select.Option>
            <Select.Option value={ProductType.SUBSCRIPTION}>
              Subscription
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Platform"
          name="platform"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option
              value={ProductPlatform.PLAY_STORE}
              children={'Play Store'}
            />
            <Select.Option
              value={ProductPlatform.APP_STORE}
              children={'App Store'}
            />
          </Select>
        </Form.Item>

        <Form.Item label="Meta">
          {type && isHasMetadata(type) ? <MetaCreate type={type} /> : <Empty />}
        </Form.Item>
      </Form>
    </Create>
  );
};
