import { FC } from 'react';
import { Tag } from '@pankod/refine-antd';
import { camelCaseToLabel } from 'src/utils/text.utils';

interface Props {
  value: string;
}

export const Status: FC<Props> = ({ value }) => (
  <Tag color="cyan">{camelCaseToLabel(value)}</Tag>
);
