import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField } from '@pankod/refine-antd';

import { UserHeroDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { ShowItem } from 'src/components/show-item';

export const UserHeroShow: FC = () => {
  const { queryResult } = useShow<UserHeroDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="Full Name">{record?.fullName}</ShowItem>

      <ShowItem title="Phone">{record?.phone}</ShowItem>

      <ShowItem title="User">
        {record && (
          <ReferenceOne
            resource="users"
            label={record.user.username}
            id={record.user.id}
          />
        )}
      </ShowItem>

      <ShowItem title="Updated">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
