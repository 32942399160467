import { useForm as refineUseForm } from '@pankod/refine-antd';
import { BaseRecord } from '@pankod/refine-core';
import { RefineUseFormProps } from 'src/types/refine';

interface Props<V, RV = V> extends RefineUseFormProps {
  // in that handler you can return new values
  onFinish?: (values: V) => void | RV | Promise<RV>;
  // in these you can only track send action
  beforeSend?: (values: V) => void | Promise<void>;
  afterSend?: () => void | Promise<void>;
}

export const useForm = <T extends BaseRecord, V = T, RV = unknown>({
  beforeSend,
  afterSend,
  onFinish,
  ...props
}: Props<V, RV> = {}) => {
  const { saveButtonProps, form, ...rest } = refineUseForm<T>({
    onMutationSuccess: async (...args) => {
      await afterSend?.();

      props?.onMutationSuccess?.(...args);
    },
  });

  const original = saveButtonProps.onClick;

  saveButtonProps.onClick = async () => {
    const values = form.getFieldsValue();

    if (beforeSend) {
      await beforeSend(values as V);
    }

    original();
  };

  const finishHandler = async (values: V) => {
    const finishResult = await onFinish?.(values);
    const finalValues = finishResult || values;

    await rest.onFinish(finalValues as Record<string, unknown>);
  };

  return {
    saveButtonProps,
    form,
    ...rest,
    formProps: {
      ...rest.formProps,
      onFinish: (values: Record<string, unknown>) =>
        finishHandler(values as unknown as V),
    },
    onFinish: finishHandler,
  };
};
