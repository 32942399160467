import { FC } from 'react';

import { AntdLayout, Typography, Avatar, Space } from '@pankod/refine-antd';

import { useGetIdentity } from '@pankod/refine-core';

const { Text } = Typography;

export const Header: FC = () => {
  const { data: user } = useGetIdentity();

  const shouldRenderHeader = user && (user.name || user.avatar);

  return shouldRenderHeader ? (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Space>
        {user.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user.avatar && (
          <Avatar size="large" src={user?.avatar} alt={user?.name} />
        )}
      </Space>
    </AntdLayout.Header>
  ) : null;
};
