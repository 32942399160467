import { FC } from 'react';
import { Button, Form, Input, Select, Space } from '@pankod/refine-antd';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { DetailItemType } from 'src/dtos';
import { NestedContainer } from 'src/components/nested-container';
import { TableSelect } from 'src/components/table-select';
import styles from './styles.module.css';

export const DetailsForm: FC = () => {
  const sort = (res: DropResult, move: (from: number, to: number) => void) => {
    const from = res.source.index;
    const to = res.destination?.index ?? res.source.index;

    return move(from, to);
  };

  return (
    <Form.List name="details">
      {(fields, { add, remove, move }) => (
        <>
          <DragDropContext onDragEnd={res => sort(res, move)}>
            <Droppable droppableId="details">
              {droppable => (
                <div ref={droppable.innerRef} {...droppable.droppableProps}>
                  {fields.map(({ name }, i) => (
                    <Draggable key={i} draggableId={i.toString()} index={i}>
                      {draggable => (
                        <div
                          ref={draggable.innerRef}
                          {...draggable.draggableProps}
                          {...draggable.dragHandleProps}
                        >
                          <NestedContainer>
                            <Space align="center">
                              <Form.Item
                                label="Type"
                                name={[name, 'type']}
                                rules={[{ required: true }]}
                                tooltip="Premium = with green bold color"
                              >
                                <TableSelect>
                                  <Select.Option value={DetailItemType.DEFAULT}>
                                    Default
                                  </Select.Option>

                                  <Select.Option value={DetailItemType.PREMIUM}>
                                    Premium
                                  </Select.Option>
                                </TableSelect>
                              </Form.Item>

                              <Form.Item
                                label="Key"
                                name={[name, 'key']}
                                rules={[{ required: true }]}
                                tooltip="locize key"
                              >
                                <Input />
                              </Form.Item>

                              <Form.Item
                                label="Body Key"
                                name={[name, 'bodyKey']}
                                rules={[{ required: false }]}
                                getValueFromEvent={({ target: { value } }) =>
                                  value?.trim() ? value : undefined
                                }
                                tooltip="locize key"
                              >
                                <Input />
                              </Form.Item>

                              <MinusCircleOutlined
                                className={styles['minus']}
                                onClick={() => remove(i)}
                              />
                            </Space>
                          </NestedContainer>
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {droppable.placeholder}
                </div>
              )}
            </Droppable>

            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Item
            </Button>
          </DragDropContext>
        </>
      )}
    </Form.List>
  );
};
