import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, Empty } from '@pankod/refine-antd';

import { PaymentDTO } from 'src/dtos';
import { Status } from 'src/components/status';
import { ShowItem } from 'src/components/show-item';
import { ReferenceOne } from 'src/components/reference-one';

export const PaymentShow: FC = () => {
  const { queryResult } = useShow<PaymentDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="User">
        {record?.user && (
          <ReferenceOne
            id={record.user.id}
            label={record.user.username}
            resource="users"
          />
        )}
      </ShowItem>

      <ShowItem title="Price">{record?.price}</ShowItem>

      <ShowItem title="Provider">
        {record?.provider && <Status value={record.provider} />}
      </ShowItem>

      <ShowItem title="Environment">
        {record?.environment && <Status value={record.environment} />}
      </ShowItem>

      <ShowItem title="Transaction Id">{record?.transactionId}</ShowItem>

      <ShowItem title="Transaction Original Id">
        {record?.transactionOriginalId}
      </ShowItem>

      <ShowItem title="Status">
        {record?.status && <Status value={record.status} />}
      </ShowItem>

      <ShowItem title="Handle Status">
        {record?.handleStatus && <Status value={record.handleStatus} />}
      </ShowItem>

      <ShowItem title="Store Product Id">{record?.storeProductId}</ShowItem>

      <ShowItem title="Original Product">
        {record?.originalProduct ? (
          <ReferenceOne
            id={record.originalProduct.id}
            label={record.originalProduct.title}
            resource="products"
          />
        ) : (
          <Empty />
        )}
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
