import { FC, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
} from '@pankod/refine-antd';
import { TagOutlined } from '@ant-design/icons';
import { api, isNotOk } from 'src/services/http.service';
import { UserReportStatus } from 'src/constants/enums';
import { useInvalidate } from '@pankod/refine-core';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const ChangeStatusButton: FC<Props> = ({
  hideText,
  size,
  recordItemId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const invalidate = useInvalidate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const clear = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const handleFinish = async ({
    status,
    adminComment,
  }: {
    status: UserReportStatus;
    adminComment: string;
  }) => {
    const res = await api.put(`user-reports/${recordItemId}/status`, {
      status,
      adminComment,
    });

    if (isNotOk(res.status)) {
      return notification.error({
        message: 'Error',
        description: 'During status change',
      });
    }

    notification.success({
      message: 'Success',
      description: 'Status has been changed',
    });

    invalidate({
      resource: 'user-reports',
      id: recordItemId,
      invalidates: ['resourceAll'],
    });

    clear();
  };

  return (
    <>
      <Button size={size} icon={<TagOutlined />} onClick={showModal}>
        {!hideText && 'Change Status'}
      </Button>

      <Modal
        title="Change status"
        open={isModalVisible}
        okText="Submit"
        onOk={handleOk}
        onCancel={clear}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              { required: true },
              { enum: [UserReportStatus.RESOLVED, UserReportStatus.WAITING] },
            ]}
          >
            <Select
              options={[
                { value: UserReportStatus.RESOLVED },
                { value: UserReportStatus.WAITING },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Admin Comment"
            name="adminComment"
            rules={[{ required: true }, { type: 'string', max: 2000, min: 1 }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
