import Input, { PasswordProps } from 'antd/lib/input';
import { FC } from 'react';
import { clsx } from 'src/utils/clsx.utils';
import styles from './styles.module.css';

export const PasswordInput: FC<PasswordProps> = props => (
  <Input.Password
    {...props}
    className={clsx(props.className, styles['password-input'])}
  />
);
