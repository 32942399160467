import { FC } from 'react';

import {
  List,
  Table,
  Space,
  ShowButton,
  Row,
  Col,
  useSelect,
  EditButton,
  DeleteButton,
} from '@pankod/refine-antd';

import { ProductDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { UserSubscriptionDTO } from 'src/dtos';
import { ProductType } from 'src/constants/enums';
import { useTable } from 'src/hooks/use-table';
import {
  booleanColumn,
  dateColumn,
  idColumn,
  relationColumn,
  textColumn,
} from 'src/utils/list.utils';

export const UserSubscriptionList: FC = () => {
  const { tableProps, filterContext } = useTable<UserSubscriptionDTO>();

  const { selectProps: subscriptionSelectProps } = useSelect<ProductDTO>({
    resource: 'products',
    optionLabel: 'title',
    filters: [
      { field: 'type', operator: 'eq', value: ProductType.SUBSCRIPTION },
    ],
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {idColumn({
              filterContext,
              field: 'userId',
              title: 'User ID',
              render: (_, record: UserSubscriptionDTO) => (
                <ReferenceOne
                  resource="users"
                  id={record.user.id}
                  label={record.user.username}
                />
              ),
            })}
            {relationColumn({
              filterContext,
              field: 'subscriptionId',
              selectProps: subscriptionSelectProps,
              render: (
                _,
                { subscription, subscriptionId }: UserSubscriptionDTO,
              ) => (
                <>
                  {subscription ? (
                    <ReferenceOne
                      resource="products"
                      id={subscription.id}
                      label={subscription.title}
                    />
                  ) : (
                    <ReferenceOne
                      resource="products"
                      id={subscriptionId}
                      label="soft deleted product"
                    />
                  )}
                </>
              ),
            })}
            {booleanColumn({ filterContext, field: 'active' })}
            {booleanColumn({ filterContext, field: 'isTest' })}
            {textColumn({
              filterContext,
              field: 'transactionOriginalId',
              render: value => (value === null ? 'null' : value),
            })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<UserSubscriptionDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
