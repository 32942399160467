import { FC } from 'react';
import { Create, Form, Input, Select, useForm } from '@pankod/refine-antd';

import { AdminDTO } from 'src/dtos';
import { AdminRole } from 'src/constants/enums';
import { PasswordInput } from 'src/components/password-input';
import {
  USERNAME_REGEX,
  WRONG_USERNAME_MESSAGE,
} from 'src/constants/validation';

export const AdminCreate: FC = () => {
  const { formProps, saveButtonProps } = useForm<AdminDTO>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
            },
            {
              type: 'string',
              pattern: USERNAME_REGEX,
              message: WRONG_USERNAME_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <PasswordInput />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={AdminRole.ADMIN}>Admin</Select.Option>
            <Select.Option value={AdminRole.SUPER_ADMIN}>
              Super Admin
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
