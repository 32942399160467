import { FC, useState } from 'react';
import { Create, RcFile, Upload, notification } from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';
import { api } from 'src/services/http.service';

export const AdStandardCoverCreate: FC = () => {
  const { list } = useNavigation();
  const [file, setFile] = useState<RcFile | null>(null);

  const onClick = async () => {
    if (!file) {
      return notification.error({ message: 'file is not attached' });
    }

    const formData = new FormData();

    formData.set('file', file);

    const { status } = await api.post('ad-standard-covers', formData);

    if (status !== 201) {
      notification.error({ message: 'Error during standard cover creating' });
      return;
    }

    notification.success({ message: 'Ad standard cover successfully created' });
    list('ad-standard-covers');
  };

  const beforeUpload = (rcFile: RcFile) => {
    setFile(rcFile);

    return false;
  };

  const onRemove = () => {
    setFile(null);
  };

  return (
    <Create saveButtonProps={{ onClick }}>
      <Upload.Dragger
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept="image/jpeg,image/png"
        multiple={false}
        maxCount={1}
        listType="picture"
      >
        Upload
      </Upload.Dragger>
    </Create>
  );
};
