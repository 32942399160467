import { usePermissions } from '@pankod/refine-core';
import { AdminRole } from 'src/constants/enums';

export const useRole = () => {
  const { data: role } = usePermissions<AdminRole>();

  const isAdmin = () => role === AdminRole.ADMIN;
  const isSuperAdmin = () => role === AdminRole.SUPER_ADMIN;

  return {
    isAdmin,
    isSuperAdmin,
    role,
  };
};
