export const titleFormatter = (text: string): string => {
  return text.toLowerCase().replace(/(^|\s)\S/g, t => t.toUpperCase());
};

export const capitalize = (text: string) =>
  `${text[0].toUpperCase()}${text.slice(1)}`;

export const camelCaseToLabel = (text: string) => {
  const withoutCapitalizedFirst = text.replace(/([A-Z])/g, ' $1').trim();

  return capitalize(withoutCapitalizedFirst);
};

export const formatUserReportType = (value: string) => {
  return value
    .replace('report_reason_', '')
    .split('_')
    .map(value => capitalize(value))
    .join(' ');
};
