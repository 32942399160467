import { Form, Input } from '@pankod/refine-antd';
import { FC } from 'react';
import { NestedContainer } from 'src/components/nested-container';
import { RegisterMethod } from 'src/constants/enums';

const methodsWithMetadata = [
  RegisterMethod.APPLE,
  RegisterMethod.GOOGLE,
  RegisterMethod.FACEBOOK,
];

export const isHasMetadata = (method?: RegisterMethod) => {
  if (!method) {
    return false;
  }

  return methodsWithMetadata.includes(method);
};

const Apple: FC = () => (
  <NestedContainer>
    <Form.Item
      label="Apple Id Token"
      name={['registerMeta', 'appleIdToken']}
      rules={[{ required: true, type: 'string' }]}
    >
      <Input disabled />
    </Form.Item>
  </NestedContainer>
);

const Google: FC = () => (
  <NestedContainer>
    <Form.Item
      label="Google Id Token"
      name={['registerMeta', 'googleIdToken']}
      rules={[{ required: true, type: 'string' }]}
    >
      <Input disabled />
    </Form.Item>
  </NestedContainer>
);

const Facebook: FC = () => (
  <NestedContainer>
    <Form.Item
      label="Facebook Access Token"
      name={['registerMeta', 'facebookAccessToken']}
      rules={[{ required: true, type: 'string' }]}
    >
      <Input disabled />
    </Form.Item>
  </NestedContainer>
);

const Unknown: FC = () => <div>Unknown</div>;

interface Props {
  method?: RegisterMethod;
}

export const RegisterMetaEdit: FC<Props> = ({ method }) => {
  switch (method) {
    case RegisterMethod.APPLE:
      return <Apple />;
    case RegisterMethod.GOOGLE:
      return <Google />;
    case RegisterMethod.FACEBOOK:
      return <Facebook />;
    default:
      return <Unknown />;
  }
};
