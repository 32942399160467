export const availabilityCalendarKeys = [
  {
    value: 'sun',
    label: 'Sunday',
  },
  {
    value: 'mon',
    label: 'Monday',
  },
  {
    value: 'tue',
    label: 'Tuesday',
  },
  {
    value: 'wed',
    label: 'Wednesday',
  },
  {
    value: 'thu',
    label: 'Thursday',
  },
  {
    value: 'fri',
    label: 'Friday',
  },
  {
    value: 'sat',
    label: 'Saturday',
  },
];
