import { RcFile, Upload } from '@pankod/refine-antd';
import { FC } from 'react';
import { ImageDTO, isImageDTO } from 'src/dtos';
import { InboxOutlined } from '@ant-design/icons';
import { getImageDTOName } from 'src/utils/image.utils';

interface RcFileWithPreview extends RcFile {
  thumbUrl?: string;
}

interface Props {
  value?: ImageDTO | RcFile;
  onChange?: (file: RcFile | null) => void;
}

const getFileList = (value?: ImageDTO | RcFileWithPreview) => {
  if (!value) {
    return [];
  }

  if (isImageDTO(value)) {
    return [
      { uid: value.id, name: getImageDTOName(value), thumbUrl: value.origin },
    ];
  }

  value.thumbUrl = URL.createObjectURL(value);

  return [value];
};

export const ImageInput: FC<Props> = ({ value, onChange }) => {
  const beforeUpload = (rcFile: RcFile) => {
    onChange?.(rcFile);

    return false;
  };

  const onRemove = () => {
    onChange?.(null);

    return false;
  };

  const fileList = getFileList(value);

  return (
    <Upload.Dragger
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      accept="image/jpeg,image/png"
      multiple={false}
      maxCount={1}
      name="Upload Cover"
      fileList={fileList}
      listType="picture"
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload and replace previous
      </p>
    </Upload.Dragger>
  );
};
