import { notification } from '@pankod/refine-antd';
import { UserFormActivitiesDTO } from 'src/dtos';
import { api, isNotOk } from './http.service';

export const replaceUserActivities = async (
  userId: string,
  activities: UserFormActivitiesDTO,
) => {
  const { status } = await api.put(`users/${userId}/activities`, {
    activities,
  });

  if (isNotOk(status)) {
    notification.error({ message: 'Error during activities replace' });
  }
};
