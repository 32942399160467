import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import type {
  QueryFilter,
  QueryFilterArr,
  QuerySort,
  QuerySortArr,
  QuerySortOperator,
  ComparisonOperator,
} from '@nestjsx/crud-request';

import type {
  DataProvider,
  CrudFilters,
  CrudOperators,
  CrudSorting,
} from '@pankod/refine-core';

import type { AxiosInstance } from 'axios';
import { stringify } from 'query-string';
import { bootstrap } from 'src/services/http.service';

type SortBy = QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>;
type AppCrudFilters =
  | QueryFilter
  | QueryFilterArr
  | Array<QueryFilter | QueryFilterArr>;

const mapOperator = (operator: CrudOperators): ComparisonOperator => {
  switch (operator) {
    case 'ne':
      return CondOperator.NOT_EQUALS;
    case 'lt':
      return CondOperator.LOWER_THAN;
    case 'gt':
      return CondOperator.GREATER_THAN;
    case 'lte':
      return CondOperator.LOWER_THAN_EQUALS;
    case 'gte':
      return CondOperator.GREATER_THAN_EQUALS;
    case 'in':
      return CondOperator.IN;
    case 'nin':
      return CondOperator.NOT_IN;
    case 'contains':
      return CondOperator.CONTAINS_LOW;
    case 'ncontains':
      return CondOperator.EXCLUDES_LOW;
    case 'containss':
      return CondOperator.CONTAINS;
    case 'ncontainss':
      return CondOperator.EXCLUDES;
    case 'null':
      return CondOperator.IS_NULL;
  }

  return CondOperator.EQUALS;
};

const generateSort = (sort?: CrudSorting): SortBy | undefined => {
  if (sort && sort.length > 0) {
    const multipleSort: SortBy = [];
    sort.map(({ field, order }) => {
      if (field && order) {
        multipleSort.push({
          field: field,
          order: order.toUpperCase() as QuerySortOperator,
        });
      }
    });

    return multipleSort;
  }

  return;
};

const generateFilter = (filters?: CrudFilters): AppCrudFilters => {
  const crudFilters: AppCrudFilters = [];
  if (filters) {
    filters.map((filter: any) => {
      const { field, operator, value } = filter;
      crudFilters.push({
        field,
        operator: mapOperator(operator),
        value,
      });
    });
  }

  return crudFilters;
};

export const customNestJsCrudDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = bootstrap(),
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort }) => {
    const url = `${apiUrl}/${resource}`;
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const generatedFilters = generateFilter(filters);

    const query = RequestQueryBuilder.create()
      .setFilter(generatedFilters)
      .setLimit(pageSize)
      .setPage(current)
      .setOffset((current - 1) * pageSize);

    const sortBy = generateSort(sort);
    if (sortBy) {
      query.sortBy(sortBy);
    }

    const { data } = await httpClient.get(`${url}?${query.query()}`);

    return {
      data: data.data,
      total: data.meta.total,
    };
  },

  getMany: async ({ resource, ids }) => {
    const url = `${apiUrl}/${resource}`;

    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: ids,
      })
      .query();

    const { data } = await httpClient.get(`${url}?${query}`);

    return {
      data: data.data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data: data.data,
    };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.put(url, variables);

    return {
      data: data.data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async id => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables,
        );

        return data;
      }),
    );

    return { data: response };
  },

  createMany: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}/bulk`;

    const { data } = await httpClient.post(url, { bulk: variables });

    return {
      data: data.data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data: data.data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      data: data.data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async id => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);

        return data;
      }),
    );

    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    const requestQueryBuilder = RequestQueryBuilder.create().setFilter(
      generateFilter(filters),
    );

    const sortBy = generateSort(sort);
    if (sortBy) {
      requestQueryBuilder.sortBy(sortBy);
    }

    let requestUrl = `${url}?${requestQueryBuilder.query()}`;

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...(headers as typeof httpClient.defaults.headers),
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
