import { FC } from 'react';
import {
  Form,
  useForm,
  useSelect,
  Select,
  Create,
  DatePicker,
  Input,
} from '@pankod/refine-antd';

import { ProductDTO, UserDTO, UserSubscriptionDTO } from 'src/dtos';
import { ProductType } from 'src/constants/enums';
import { BooleanInput } from 'src/components/boolean-input';
import { date } from 'src/utils/date.utils';

export const UserSubscriptionCreate: FC = () => {
  const { formProps, saveButtonProps } = useForm<UserSubscriptionDTO>();

  const { selectProps: userSelectProps } = useSelect<UserDTO>({
    resource: 'users',
    optionLabel: 'username',
  });

  const { selectProps: subscriptionSelectProps } = useSelect<ProductDTO>({
    resource: 'products',
    optionLabel: 'title',
    filters: [
      { field: 'type', operator: 'eq', value: ProductType.SUBSCRIPTION },
    ],
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="User"
          name="userId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>

        <Form.Item
          label="Subscription"
          name="subscriptionId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...subscriptionSelectProps} />
        </Form.Item>

        <Form.Item
          label="Transaction Original Id"
          name="transactionOriginalId"
          rules={[
            {
              required: false,
            },
            {
              type: 'string',
              min: 1,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Active"
          name="active"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={false}
        >
          <BooleanInput disabled />
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={new Date().toISOString()}
          {...date}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Is Test"
          name="isTest"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue
        >
          <BooleanInput />
        </Form.Item>
      </Form>
    </Create>
  );
};
