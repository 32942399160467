import { defineResource } from 'src/utils/define-resource.utils';

import { HeartOutlined } from '@ant-design/icons';

import { ActivityList } from './activity.list';
import { ActivityCreate } from './activity.create';
import { ActivityEdit } from './activity.edit';
import { ActivityShow } from './activity.show';

export default defineResource({
  name: 'activities',
  list: ActivityList,
  create: ActivityCreate,
  edit: ActivityEdit,
  show: ActivityShow,
  icon: <HeartOutlined />,
  parentName: 'cms',
});
