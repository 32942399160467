import { FC } from 'react';
import { Button, notification, Popconfirm } from '@pankod/refine-antd';
import { UndoOutlined } from '@ant-design/icons';
import { useInvalidate, useResource } from '@pankod/refine-core';
import { singular } from 'pluralize';
import { api, isNotOk } from 'src/services/http.service';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const RecoverButton: FC<Props> = ({ hideText, size, recordItemId }) => {
  const { resourceName } = useResource();
  const invalidate = useInvalidate();

  const handleConfirm = async () => {
    const { status } = await api.patch(
      `${resourceName}/${recordItemId}/recover`,
    );

    const entity = singular(resourceName);

    if (isNotOk(status)) {
      return notification.error({
        message: 'Error',
        description: `Error during ${entity} recover`,
      });
    }

    notification.success({
      message: 'Success',
      description: `${entity} successfully recovered`,
    });

    invalidate({ resource: resourceName, invalidates: ['list'] });
  };

  return (
    <Popconfirm
      key="recover"
      okText="Recover"
      cancelText="Cancel"
      title="Are you sure?"
      onConfirm={handleConfirm}
    >
      <Button ghost size={size} icon={<UndoOutlined />}>
        {!hideText && 'Recover'}
      </Button>
    </Popconfirm>
  );
};
