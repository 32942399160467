import { ProductType, ProductPlatform } from 'src/constants/enums';
import { BaseDTO } from './base.dto';
import { ImageDTO } from './image.dto';

export interface SubscriptionMeta {
  userFeedSearchAccess: boolean;
  matchesPerDayLimit: number;
  emergencyCallAccess: boolean;
  likesLimit: boolean;
}

export interface AdMeta {
  daysCount: number;
}

export enum DetailItemType {
  DEFAULT = 'default',
  PREMIUM = 'premium',
}

export interface DetailItem {
  type: DetailItemType;
  key: string;
  bodyKey?: string;
}

interface Base extends BaseDTO {
  title: string;
  details?: DetailItem[] | null;
  color: string;
  storeProductId: string;
  cover?: ImageDTO;
  platform: ProductPlatform;
}

interface Ad extends Base {
  meta: AdMeta;
  type: ProductType.AD;
}

interface Subscription extends Base {
  meta: SubscriptionMeta;
  type: ProductType.SUBSCRIPTION;
}

export type ProductDTO = Ad | Subscription;
