export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export const TOKENS_KEY = 'refine-auth';

export const setAuth = (tokens: Tokens) => {
  localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens));
};

export const removeAuth = () => {
  localStorage.removeItem(TOKENS_KEY);
};

export const getAuth = () => {
  try {
    const json = localStorage.getItem(TOKENS_KEY);
    if (!json) return null;

    const { accessToken, refreshToken } = JSON.parse(json) as Partial<Tokens>;

    if (!accessToken || !refreshToken) {
      removeAuth();

      return null;
    }

    return { accessToken, refreshToken };
  } catch (error) {
    removeAuth();

    return null;
  }
};
