import { FC } from 'react';

import {
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  DeleteButton,
  TagField,
} from '@pankod/refine-antd';

import { UserDTO } from 'src/dtos';
import {
  EnumGender,
  EnumGenderColors,
  OnboardingStepEnum,
  OnboardingStepColors,
  RegisterMethod,
} from 'src/constants/enums';
import { titleFormatter } from 'src/utils/text.utils';
import { SetPasswordButton } from './set-password/set-password.button';
import { CreateNotificationButton } from './create-notification/create-notification.button';
import { useTable } from 'src/hooks/use-table';
import {
  booleanColumn,
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';
import { useRole } from 'src/hooks/use-role';
import { PardonButton } from './pardon/pardon.button';
import { BanButton } from './ban/ban.button';

export const UserList: FC = () => {
  const { tableProps, filterContext } = useTable<UserDTO>();
  const { isSuperAdmin } = useRole();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'username' })}
            {textColumn({
              filterContext,
              field: 'fullname',
              title: 'Full Name',
            })}
            {textColumn({ filterContext, field: 'email' })}
            {enumColumn({
              filterContext,
              field: 'gender',
              enum: EnumGender,
              render: value => (
                <TagField
                  value={titleFormatter(value)}
                  color={EnumGenderColors[value] || 'default'}
                />
              ),
            })}
            {enumColumn({
              filterContext,
              field: 'onboardingStep',
              enum: OnboardingStepEnum,
              render: value => (
                <TagField
                  value={titleFormatter(value)}
                  color={OnboardingStepColors[value] || 'default'}
                />
              ),
            })}
            {textColumn({ filterContext, field: 'country' })}
            {textColumn({ filterContext, field: 'state' })}
            {textColumn({ filterContext, field: 'city' })}
            {enumColumn({
              filterContext,
              field: 'registerMethod',
              enum: RegisterMethod,
            })}
            {booleanColumn({ filterContext, field: 'isIntroduction' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<UserDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {isSuperAdmin() && (
                    <CreateNotificationButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                  {record.ban ? (
                    <PardonButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  ) : (
                    <BanButton hideText size="small" recordItemId={record.id} />
                  )}
                  <SetPasswordButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {isSuperAdmin() && (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
