import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, ImageField } from '@pankod/refine-antd';

import { EmergencyCallDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { Status } from 'src/components/status';
import { Location } from 'src/components/location';
import { ShowItem } from 'src/components/show-item';

export const EmergencyCallShow: FC = () => {
  const { queryResult } = useShow<EmergencyCallDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="User">
        {record && (
          <ReferenceOne
            resource="users"
            id={record.user.id}
            label={record.user.username}
          />
        )}
      </ShowItem>

      {record?.appearance && (
        <ShowItem title="Appearance">{record.appearance}</ShowItem>
      )}

      {record?.location && (
        <ShowItem title="Location">
          <Location location={record.location} />
        </ShowItem>
      )}

      {record?.locationDescription && (
        <ShowItem title="Location description">
          {record.locationDescription}
        </ShowItem>
      )}

      {record?.participants && (
        <ShowItem title="Participants">
          {record.participants.length === 0 && 'None'}
          {record.participants.map((user, i) => (
            <ReferenceOne
              key={`${user.id}-${i}`}
              resource="users"
              id={user.id}
              label={user.username}
            />
          ))}
        </ShowItem>
      )}

      {record?.heroes && (
        <ShowItem title="Heroes">
          {record.heroes.map((hero, i) => (
            <ReferenceOne
              key={`${hero.id}-${i}`}
              resource="user-heroes"
              id={hero.id}
              label={hero.fullName}
            />
          ))}
        </ShowItem>
      )}

      {record?.selfie && (
        <ShowItem title="Selfie">
          <ImageField value={record?.selfie.origin} />
        </ShowItem>
      )}

      {record?.pinCode && (
        <ShowItem title="Pin Code">{record.pinCode}</ShowItem>
      )}

      {record?.status && (
        <ShowItem title="Status">
          <Status value={record.status} />
        </ShowItem>
      )}

      <ShowItem title="Start Date">
        <DateField format="LLL" value={record?.startDate} />
      </ShowItem>

      <ShowItem title="End Date">
        <DateField format="LLL" value={record?.endDate} />
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
