import { FC, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
} from '@pankod/refine-antd';
import { BellOutlined } from '@ant-design/icons';
import { Events, NotificationProviderName } from 'src/constants/enums';
import { NestedContainer } from 'src/components/nested-container';
import { api, isNotOk } from 'src/services/http.service';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

interface NotificationCreateDTO {
  event: Events;
  providers: NotificationProviderName[];
  data?: {
    id?: string;
    subId?: string;
    time?: number;
    msg?: string;
  };
}

export const CreateNotificationButton: FC<Props> = ({
  hideText,
  size,
  recordItemId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const handleFinish = async (dto: NotificationCreateDTO) => {
    const { status } = await api.post(
      `users/${recordItemId}/notifications`,
      dto,
    );

    if (isNotOk(status)) {
      return notification.error({
        message: 'Error',
        description: 'During create notification',
      });
    }

    notification.success({
      message: 'Success',
      description: 'Notification successfully created',
    });

    setIsModalVisible(false);
  };

  return (
    <>
      <Button size={size} icon={<BellOutlined />} onClick={showModal}>
        {!hideText && 'Create notification'}
      </Button>

      <Modal
        title="Create notification"
        open={isModalVisible}
        okText="Submit"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            label="Event"
            name="event"
            rules={[{ required: true }, { enum: Object.values(Events) }]}
          >
            <Select>
              {Object.entries(Events).map(([key, value], i) => (
                <Select.Option key={i} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Providers"
            name="providers"
            rules={[
              {
                required: true,
              },
              {
                type: 'array',
              },
            ]}
          >
            <Select mode="multiple">
              <Select.Option value={NotificationProviderName.PUSH}>
                Push
              </Select.Option>

              <Select.Option value={NotificationProviderName.WS}>
                WS
              </Select.Option>
            </Select>
          </Form.Item>

          <Divider />

          <Form.Item label="Data" />

          <NestedContainer>
            <Form.Item
              label="Id"
              name={['data', 'id']}
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="SubId"
              name={['data', 'subId']}
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Time"
              name={['data', 'time']}
              rules={[{ required: false }, { type: 'number' }]}
            >
              <InputNumber style={{ minWidth: '100%' }} min={0} />
            </Form.Item>

            <Form.Item
              label="Msg"
              name={['data', 'msg']}
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </NestedContainer>
        </Form>
      </Modal>
    </>
  );
};
