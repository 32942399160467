import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, Empty, ImageField } from '@pankod/refine-antd';

import { ProductDTO } from 'src/dtos';
import { Status } from 'src/components/status';
import { ShowItem } from 'src/components/show-item';
import { MetaShow } from './meta/meta.show';
import { ShowColor } from 'src/components/show-color';
import { DetailsShow } from './details/details.show';
import { useRole } from 'src/hooks/use-role';

export const ProductShow: FC = () => {
  const { isSuperAdmin } = useRole();
  const { queryResult } = useShow<ProductDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      canEdit={isSuperAdmin()}
      canDelete={isSuperAdmin()}
    >
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="Title">{record?.title}</ShowItem>

      <ShowItem title="Details">
        {record?.details && record.details.length && (
          <DetailsShow details={record.details} />
        )}

        {(!record?.details || !record.details.length) && <Empty />}
      </ShowItem>

      <ShowItem title="Color">
        {record?.color && <ShowColor value={record?.color} />}
      </ShowItem>

      <ShowItem title="Store Product Id">{record?.storeProductId}</ShowItem>

      <ShowItem title="Cover">
        {record?.cover ? (
          <ImageField value={record.cover.origin} alt="product cover" />
        ) : (
          <Empty />
        )}
      </ShowItem>

      <ShowItem title="Type">
        {record && <Status value={record.type} />}
      </ShowItem>

      <ShowItem title="Platform">{record?.platform}</ShowItem>

      <ShowItem title="Meta">
        {record?.meta && record?.type ? <MetaShow {...record} /> : <Empty />}
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>

      <ShowItem title="Deleted At">
        {record?.deletedAt === null ? (
          'null'
        ) : (
          <DateField format="LLL" value={record?.deletedAt} />
        )}
      </ShowItem>
    </Show>
  );
};
