import { RcFile } from '@pankod/refine-antd';
import { ImageDTO } from 'src/dtos';

export const enum ImageValueType {
  UPLOADED = 'uploaded',
  PICKED = 'picked',
}

interface Base {
  uid: string;
  name: string;
  thumbUrl: string;
}

export interface UploadedImageValueDTO extends Base {
  type: ImageValueType.UPLOADED;
  data: ImageDTO;
}

export interface PickedImageValueDTO extends Base {
  type: ImageValueType.PICKED;
  data: RcFile;
}

export type ImageValueDTO = UploadedImageValueDTO | PickedImageValueDTO;

export const rcFileToImageValue = (rcFile: RcFile): PickedImageValueDTO => ({
  uid: rcFile.uid,
  name: rcFile.name,
  thumbUrl: URL.createObjectURL(rcFile),
  type: ImageValueType.PICKED,
  data: rcFile,
});

export const getImageDTOName = (imageDTO: ImageDTO) => {
  const name = imageDTO.origin.split('/').at(-2);

  if (name) {
    return name;
  }

  return imageDTO.origin;
};

export const imageDTOToImageValue = (
  imageDTO: ImageDTO,
): UploadedImageValueDTO => ({
  uid: imageDTO.id,
  name: getImageDTOName(imageDTO),
  thumbUrl: imageDTO.origin,
  type: ImageValueType.UPLOADED,
  data: imageDTO,
});

export const calculateImageDiff = (
  source: ImageDTO[],
  target: (ImageValueDTO | ImageDTO)[],
) => {
  const targetValues = target.map(dto => {
    if ('uid' in dto) {
      return dto;
    }

    return imageDTOToImageValue(dto);
  });

  const uploadedMap = targetValues
    .filter(({ type }) => type === ImageValueType.UPLOADED)
    .reduce((state, { uid }) => state.set(uid, true), new Map<string, true>());

  const upload = targetValues.filter(
    ({ type }) => type === ImageValueType.PICKED,
  ) as PickedImageValueDTO[];

  const remove = source.reduce((state, image) => {
    if (uploadedMap.has(image.id)) {
      return state;
    }

    return [...state, image.id];
  }, [] as string[]);

  return {
    remove,
    upload,
  };
};

const formValueToImageValue = (value: ImageDTO | ImageValueDTO) => {
  if ('uid' in value) {
    return value;
  }

  return imageDTOToImageValue(value);
};

const imagesGetValueProps = (value?: (ImageDTO | ImageValueDTO)[]) => ({
  value: value ? value.map(formValueToImageValue) : undefined,
});

export const images = {
  getValueProps: imagesGetValueProps,
};
