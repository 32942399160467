import dayjs, { Dayjs } from 'dayjs';

export const formatHistoryDate = (date: string) => {
  return dayjs(date).format('MM.DD.YYYY');
};

const getValueProps = (value?: string) => ({
  value: value ? dayjs(value) : '',
});

const getValueFromEvent = (value?: Dayjs) =>
  value ? value.toISOString() : null;

export const date = {
  getValueProps,
  getValueFromEvent,
};

const MIN_AGE = 18;
const MAX_AGE = 99;

const getMinBirthday = () => {
  const now = new Date();
  const min = now.getFullYear() - MIN_AGE;

  now.setFullYear(min);

  return dayjs(now);
};

const birthdayDisabledDate = (date: Dayjs) => {
  const now = new Date().getFullYear();
  const min = now - MIN_AGE;
  const max = now - MAX_AGE;
  const got = date.get('year');

  return got < max || got > min;
};

export const birthday = {
  disabledDate: birthdayDisabledDate,
  defaultPickerValue: getMinBirthday(),
};

export const time = {
  getValueProps: (value?: string) => {
    if (!value) return { value: '' };

    const [hours, minutes] = value.split(':');

    return {
      value: dayjs()
        .set('hours', Number(hours))
        .set('minutes', Number(minutes)),
    };
  },
  getValueFromEvent: (value?: Dayjs) => (value ? value?.format('HH:mm') : null),
};
