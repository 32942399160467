import { FC } from 'react';

import {
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  DeleteButton,
} from '@pankod/refine-antd';

import { UserHeroDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { useTable } from 'src/hooks/use-table';
import { dateColumn, idColumn, textColumn } from 'src/utils/list.utils';
import { useRole } from 'src/hooks/use-role';

export const UserHeroList: FC = () => {
  const { isSuperAdmin } = useRole();
  const { tableProps, filterContext } = useTable<UserHeroDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({
              filterContext,
              field: 'fullName',
            })}
            {textColumn({ filterContext, field: 'phone' })}
            {idColumn({
              filterContext,
              field: 'userId',
              title: 'User ID',
              render: (_, record: UserHeroDTO) => (
                <ReferenceOne
                  label={record.user.username}
                  id={record.user.id}
                  resource="users"
                />
              ),
            })}
            {dateColumn({ filterContext, field: 'createdAt' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}

            <Table.Column<UserHeroDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {isSuperAdmin() && (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
