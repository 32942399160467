import { Form, InputNumber } from '@pankod/refine-antd';
import { FC } from 'react';
import { BooleanInput } from 'src/components/boolean-input';
import { NestedContainer } from 'src/components/nested-container';
import { ProductType } from 'src/constants/enums';

const productsWithMetadata = [ProductType.AD, ProductType.SUBSCRIPTION];

export const isHasMetadata = (productType?: ProductType) => {
  if (!productType) {
    return false;
  }

  return productsWithMetadata.includes(productType);
};

const Subscription: FC = () => (
  <NestedContainer>
    <Form.Item
      label="User Feed Search Access"
      name={['meta', 'userFeedSearchAccess']}
      rules={[{ required: true }]}
      tooltip="Match Page Custom Search Access"
    >
      <BooleanInput />
    </Form.Item>

    <Form.Item
      label="Matches Per Day Limit"
      name={['meta', 'matchesPerDayLimit']}
      rules={[{ required: true }, { type: 'integer', min: 0 }]}
      tooltip="0 = Infinity"
    >
      <InputNumber />
    </Form.Item>

    <Form.Item
      label="Emergency Call Access"
      name={['meta', 'emergencyCallAccess']}
      rules={[{ required: true }]}
      tooltip="Emergency Call Create Access"
    >
      <BooleanInput />
    </Form.Item>

    <Form.Item
      label="Likes Limit"
      name={['meta', 'likesLimit']}
      rules={[{ required: true }]}
      tooltip="Incoming likes blur"
    >
      <BooleanInput />
    </Form.Item>
  </NestedContainer>
);

const Ad: FC = () => (
  <NestedContainer>
    <Form.Item
      label="Days Count"
      name={['meta', 'daysCount']}
      rules={[{ required: true, type: 'integer', min: 0 }]}
    >
      <InputNumber />
    </Form.Item>
  </NestedContainer>
);

const Unknown: FC = () => <div>Unknown</div>;

interface Props {
  type?: ProductType;
}

export const MetaEdit: FC<Props> = ({ type }) => {
  switch (type) {
    case ProductType.SUBSCRIPTION:
      return <Subscription />;
    case ProductType.AD:
      return <Ad />;
    default:
      return <Unknown />;
  }
};
