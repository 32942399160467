import { defineResource } from 'src/utils/define-resource.utils';
import { WalletOutlined } from '@ant-design/icons';
import { PaymentList } from './payment.list';
import { PaymentShow } from './payment.show';

export default defineResource({
  name: 'payments',
  list: PaymentList,
  show: PaymentShow,
  icon: <WalletOutlined />,
  parentName: 'users-data',
});
