import { defineResource } from 'src/utils/define-resource.utils';
import { AdStandardCoverList } from './ad-standard-cover.list';
import { AdStandardCoverCreate } from './ad-standard-cover.create';
import { PictureOutlined } from '@ant-design/icons';

export default defineResource({
  name: 'ad-standard-covers',
  list: AdStandardCoverList,
  create: AdStandardCoverCreate,
  icon: <PictureOutlined />,
  parentName: 'cms',
});
