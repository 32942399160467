import { defineResource } from 'src/utils/define-resource.utils';

import { SettingOutlined } from '@ant-design/icons';

import { SettingList } from './setting.list';
import { SettingCreate } from './setting.create';
import { SettingEdit } from './setting.edit';
import { SettingShow } from './setting.show';

export default defineResource({
  name: 'settings',
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
  show: SettingShow,
  icon: <SettingOutlined />,
  parentName: 'cms',
});
