import { FC, Fragment, useRef } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Card,
  Tabs,
  Divider,
  Space,
  DatePicker,
  InputNumber,
  Button,
  useSelect,
  Empty,
  TabsProps,
  Create,
} from '@pankod/refine-antd';

import {
  ActivityDTO,
  UserDTO,
  UserFormActivitiesDTO,
  UserFormDTO,
  UserWithoutJoinsDTO,
} from 'src/dtos';
import {
  EnumGender,
  LanguageEnum,
  OnboardingStepEnum,
  RegisterMethod,
  UnitsEnum,
  UserActivityLevelEnum,
  UserColorModeEnum,
  UserProfileVisibleEnum,
} from 'src/constants/enums';
import { birthday, date, time } from 'src/utils/date.utils';
import { MultipleImageInput } from 'src/components/multiply-image-input';
import { useForm } from 'src/hooks/use-form';
import {
  calculateImageDiff,
  images,
  ImageValueDTO,
} from 'src/utils/image.utils';
import { removePhotos, uploadPhotos } from 'src/services/user-photo.service';

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Field } from 'src/components/field';
import { TableSelect } from 'src/components/table-select';
import { replaceUserActivities } from 'src/services/user-activities.service';
import { availabilityCalendarKeys } from 'src/constants/availability-calendar';
import { notificationSettings } from 'src/constants/notification-settings';
import { BooleanInput } from 'src/components/boolean-input';
import { socialLinks } from 'src/constants/social-links';
import { isHasMetadata } from './register-meta/register-meta.edit';
import { RegisterMetaCreate } from './register-meta/register-meta.create';
import {
  LETTERS_AND_SPACE_REGEX,
  PASSWORD_REGEX,
  PHONE_MESSAGE,
  PHONE_REGEX,
  USERNAME_REGEX,
  WRONG_LETTERS_AND_SPACE_MESSAGE,
  WRONG_PASSWORD_MESSAGE,
  WRONG_USERNAME_MESSAGE,
} from 'src/constants/validation';
import { isUnique } from 'src/services/user.service';
import { LocationFormInput } from 'src/components/location-form-input';
import { PasswordInput } from 'src/components/password-input';

export const UserCreate: FC = () => {
  const photosRef = useRef<ImageValueDTO[]>();
  const activitiesRef = useRef<UserFormActivitiesDTO>();

  const { formProps, saveButtonProps, form } = useForm<
    UserDTO,
    UserFormDTO,
    UserWithoutJoinsDTO
  >({
    onFinish: values => {
      const copy: Partial<UserFormDTO> = { ...values };

      if (copy.photos) {
        photosRef.current = copy.photos;

        delete copy.photos;
      }

      if (copy.activities) {
        activitiesRef.current = copy.activities;

        delete copy.activities;
      }

      return copy as UserWithoutJoinsDTO;
    },
    onMutationSuccess: async ({ data }) => {
      const record = data as unknown as UserDTO;
      if (!record || !record?.id) {
        return;
      }

      if (photosRef.current) {
        const { remove, upload } = calculateImageDiff(
          record.photos,
          photosRef.current,
        );

        await removePhotos(record.id, remove);
        await uploadPhotos(record.id, upload);
      }

      if (activitiesRef.current) {
        const activities = activitiesRef.current.map(dto => ({
          ...dto,
          userId: record.id,
        }));

        await replaceUserActivities(record.id, activities);
      }
    },
  });

  const { selectProps } = useSelect<ActivityDTO>({
    resource: 'activities',
    optionLabel: 'name',
  });

  const registerMethod = Form.useWatch('registerMethod', form);
  const isEmailAndPassword =
    registerMethod === RegisterMethod.EMAIL_AND_PASSWORD;

  const items: TabsProps['items'] = [
    {
      // Main Tab
      label: 'Main',
      key: 'main',
      children: (
        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  pattern: USERNAME_REGEX,
                  message: WRONG_USERNAME_MESSAGE,
                },
                {
                  type: 'string',
                  validator: async (rule, value?: string) => {
                    if (!value || !value.trim().length) return;

                    if (await isUnique(value)) {
                      return;
                    }

                    throw new Error('is not unique');
                  },
                  message: 'must be unique',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Fullname"
              name="fullname"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  pattern: LETTERS_AND_SPACE_REGEX,
                  message: WRONG_LETTERS_AND_SPACE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Bio" name="bio" rules={[{ required: false }]}>
              <Input.TextArea rows={8} />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: EnumGender.MALE },
                  { value: EnumGender.FEMALE },
                  { value: EnumGender.SECRET },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Birthday"
              name="birthday"
              rules={[
                {
                  required: true,
                  type: 'date',
                },
              ]}
              {...date}
            >
              <DatePicker {...birthday} />
            </Form.Item>

            <Form.Item
              label="Hide Birthday"
              name="hideBirthday"
              rules={[{ required: true }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  pattern: PHONE_REGEX,
                  message: PHONE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone Verified"
              name="phoneVerified"
              rules={[{ required: true }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: isEmailAndPassword,
                },
                {
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email Verified"
              name="emailVerified"
              rules={[{ required: false }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: isEmailAndPassword,
                },
                {
                  type: 'string',
                  pattern: PASSWORD_REGEX,
                  message: WRONG_PASSWORD_MESSAGE,
                },
              ]}
            >
              <PasswordInput />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Register Method"
              name="registerMethod"
              rules={[{ required: true }]}
              initialValue={RegisterMethod.EMAIL_AND_PASSWORD}
            >
              <Select
                options={[
                  {
                    value: RegisterMethod.EMAIL_AND_PASSWORD,
                    label: 'email and password',
                  },
                  { value: RegisterMethod.APPLE },
                  { value: RegisterMethod.GOOGLE },
                  { value: RegisterMethod.FACEBOOK },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Register Identity"
              name="registerIdentity"
              rules={[
                {
                  required:
                    registerMethod !== RegisterMethod.EMAIL_AND_PASSWORD,
                },
              ]}
            >
              <Input disabled={isEmailAndPassword} />
            </Form.Item>

            <Form.Item label="Register Meta">
              {isHasMetadata(registerMethod) ? (
                <RegisterMetaCreate method={registerMethod} />
              ) : (
                <Empty />
              )}
            </Form.Item>
          </Col>

          <Col lg={8} xs={24}>
            <Form.Item
              label="Terms Accepted"
              name="termsAccepted"
              rules={[{ required: true }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>

            <Form.Item
              label="Privacy Accepted"
              name="privacyAccepted"
              rules={[{ required: true }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Occupancy Scale"
              name="occupancyScale"
              rules={[{ type: 'integer', required: true, min: 0 }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Onboarding Step"
              name="onboardingStep"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: OnboardingStepEnum.SIGNUP },
                  { value: OnboardingStepEnum.PROFILE },
                  { value: OnboardingStepEnum.FINISHED },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Profile Visible"
              name="profileVisible"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: UserProfileVisibleEnum.EVERYONE },
                  { value: UserProfileVisibleEnum.FRIENDS },
                ]}
              />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  pattern: LETTERS_AND_SPACE_REGEX,
                  message: WRONG_LETTERS_AND_SPACE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: false,
                },
                {
                  type: 'string',
                  pattern: LETTERS_AND_SPACE_REGEX,
                  message: WRONG_LETTERS_AND_SPACE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                },
                {
                  type: 'string',
                  pattern: LETTERS_AND_SPACE_REGEX,
                  message: WRONG_LETTERS_AND_SPACE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Language"
              name="language"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: LanguageEnum.EN },
                  { value: LanguageEnum.ES },
                  { value: LanguageEnum.RU },
                  { value: LanguageEnum.UK },
                ]}
              />
            </Form.Item>

            <Form.Item label="Units" name="units" rules={[{ required: true }]}>
              <Select
                options={[{ value: UnitsEnum.KM }, { value: UnitsEnum.ML }]}
              />
            </Form.Item>

            <Form.Item
              label="Color Mode"
              name="colorMode"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: UserColorModeEnum.WHITE },
                  { value: UserColorModeEnum.DARK },
                ]}
              />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true }]}
            >
              <LocationFormInput required />
            </Form.Item>

            <Form.Item
              label="Is Introduction"
              name="isIntroduction"
              rules={[{ required: true }]}
              initialValue={false}
            >
              <BooleanInput />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      // Photos Tab
      label: 'Photos',
      key: 'photos',
      children: (
        <Form.Item
          label="Photos"
          name="photos"
          rules={[{ required: false }]}
          {...images}
        >
          <MultipleImageInput maxCount={10} />
        </Form.Item>
      ),
    },
    {
      // Activities Tab
      label: 'Activities',
      key: 'activities',
      children: (
        <Form.Item label="Activities">
          <Form.List name="activities">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Field key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, 'activityId']}
                      rules={[{ required: true }]}
                    >
                      <TableSelect {...selectProps} />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'level']}
                      rules={[{ required: true }]}
                      initialValue={UserActivityLevelEnum.MEDIUM}
                    >
                      <Select
                        options={[
                          { value: UserActivityLevelEnum.BEGINNER },
                          { value: UserActivityLevelEnum.MEDIUM },
                          { value: UserActivityLevelEnum.ADVANCED },
                        ]}
                      />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Field>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Activity
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      ),
    },
    {
      // Other Tab
      label: 'Other',
      key: 'other',
      children: (
        <Row gutter={[16, 16]}>
          <Col lg={12} xs={24}>
            <Card title="Availability Calendar">
              {availabilityCalendarKeys.map(({ value, label }, i) => (
                <Fragment key={value}>
                  {i !== 0 && <Divider />}

                  <Form.Item
                    label={`${label} From`}
                    name={['availabilityCalendar', value, 'from']}
                    rules={[{ required: false, type: 'string' }]}
                    {...time}
                  >
                    <DatePicker.TimePicker format="HH:mm" />
                  </Form.Item>

                  <Form.Item
                    label={`${label} To`}
                    name={['availabilityCalendar', value, 'to']}
                    rules={[{ required: false, type: 'string' }]}
                    {...time}
                  >
                    <DatePicker.TimePicker format="HH:mm" />
                  </Form.Item>
                </Fragment>
              ))}
            </Card>
          </Col>
          <Col lg={12} xs={24}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              <Card title="Notification Setting">
                {notificationSettings.map(({ value, label }) => (
                  <Form.Item
                    key={`notification-setting-${label}`}
                    label={label}
                    name={['notificationSettings', value]}
                    rules={[{ required: true }]}
                    initialValue
                  >
                    <BooleanInput />
                  </Form.Item>
                ))}
              </Card>

              <Card title="Social Links">
                {socialLinks.map(({ value, label }) => (
                  <Form.Item
                    key={`social-link-${label}`}
                    label={label}
                    name={['socialLinks', value]}
                    rules={[{ required: false, type: 'string' }]}
                    getValueFromEvent={event => {
                      const value = event.target.value;

                      return value === '' ? null : value;
                    }}
                  >
                    <Input />
                  </Form.Item>
                ))}
              </Card>
            </Space>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Tabs items={items} />
      </Form>
    </Create>
  );
};
