import { FC } from 'react';
import { LocationDTO } from 'src/dtos';

interface Props {
  location: LocationDTO;
}

export const Location: FC<Props> = ({ location: { lat, lng } }) => (
  <div>
    Latitude: {lat}
    <br />
    Longitude: {lng}
  </div>
);
