import { FC } from 'react';

import {
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  DeleteButton,
} from '@pankod/refine-antd';

import { ActivityDTO } from 'src/dtos';
import { useTable } from 'src/hooks/use-table';
import {
  booleanColumn,
  dateColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';

export const ActivityList: FC = () => {
  const { tableProps, filterContext } = useTable<ActivityDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'name' })}
            {textColumn({ filterContext, field: 'iconSrc' })}
            {booleanColumn({ filterContext, field: 'active' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<ActivityDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
