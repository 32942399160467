import { FC } from 'react';
import { Create, Form, Input, useForm } from '@pankod/refine-antd';

import { ActivityDTO } from 'src/dtos';
import { BooleanInput } from 'src/components/boolean-input';

export const ActivityCreate: FC = () => {
  const { formProps, saveButtonProps } = useForm<ActivityDTO>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Icon Src"
          name="iconSrc"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Active" name="active" initialValue>
          <BooleanInput />
        </Form.Item>
      </Form>
    </Create>
  );
};
