import { FC } from 'react';
import { Tag } from '@pankod/refine-antd';
import { InternalLink } from './internal-link';
import { useResourceWithRoute } from '@pankod/refine-core';

interface Props {
  resource: string;
  label: string;
  id: string;
}

export const ReferenceOne: FC<Props> = ({ resource, label, id }) => {
  const resourceWithRoute = useResourceWithRoute();
  const { route } = resourceWithRoute(resource);

  return (
    <InternalLink href={`/${route}/show/${id}`}>
      <Tag color="green">{label}</Tag>
    </InternalLink>
  );
};
