import { Button, notification, Popconfirm } from '@pankod/refine-antd';
import { useNavigation, useResource } from '@pankod/refine-core';
import { StopOutlined } from '@ant-design/icons';
import { FC } from 'react';
import pluralize from 'pluralize';
import { api, isNotOk } from 'src/services/http.service';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const CancelButton: FC<Props> = ({
  recordItemId,
  hideText,
  ...rest
}) => {
  const { resourceName } = useResource();
  const { list } = useNavigation();

  const confirmHandler = async () => {
    const { data, status } = await api.post(
      `${resourceName}/${recordItemId}/cancel`,
    );

    if (isNotOk(status)) {
      notification.error({
        message: 'Error during canceling',
        description: data.messages[0],
      });
      return;
    }

    const resource = pluralize.singular(resourceName);
    notification.success({ message: `Successfully canceled a ${resource}` });
    list(resourceName);
  };

  return (
    <Popconfirm
      key="cancel"
      okText="Cancel"
      okType="danger"
      cancelText="Back"
      title="Are you sure?"
      onConfirm={confirmHandler}
    >
      <Button danger icon={<StopOutlined />} {...rest}>
        {!hideText && 'Cancel'}
      </Button>
    </Popconfirm>
  );
};
