import { FC } from 'react';

import {
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  DeleteButton,
} from '@pankod/refine-antd';

import { SettingDTO } from 'src/dtos';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  idColumn,
  textColumn,
  trimBigText,
} from 'src/utils/list.utils';
import { useRole } from 'src/hooks/use-role';

export const SettingList: FC = () => {
  const { isSuperAdmin } = useRole();
  const { tableProps, filterContext } = useTable<SettingDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List canCreate={isSuperAdmin()}>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'key' })}
            {textColumn({ filterContext, field: 'value', render: trimBigText })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<SettingDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {isSuperAdmin() && (
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {isSuperAdmin() && (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
