import { FC } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  useForm,
  Divider,
  useSelect,
  Select,
  Create,
} from '@pankod/refine-antd';

import { UserDTO, UserHeroDTO } from 'src/dtos';
import { api } from 'src/services/http.service';
import {
  MOBILE_PHONE_MESSAGE,
  MOBILE_PHONE_REGEX,
} from 'src/constants/validation';

const HEROES_LIMIT = 5;

export const UserHeroCreate: FC = () => {
  const { formProps, saveButtonProps } = useForm<UserHeroDTO>();
  const { selectProps } = useSelect<UserDTO>({
    resource: 'users',
    optionLabel: 'username',
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                {
                  required: true,
                },
                {
                  min: 5,
                },
                {
                  max: 255,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: MOBILE_PHONE_REGEX,
                  message: MOBILE_PHONE_MESSAGE,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item
              label="User"
              name="userId"
              rules={[
                {
                  required: true,
                },
                {
                  validator: async (_, value) => {
                    if (!value) return;

                    const { data: json } = await api.get(
                      `user-heroes?filter=userId||$eq||${value}`,
                    );

                    if (json.data.length >= HEROES_LIMIT) {
                      throw new Error('user reaches user-hero limit');
                    }
                  },
                },
              ]}
            >
              <Select {...selectProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
