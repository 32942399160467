import { FC } from 'react';

import {
  List,
  Table,
  Space,
  Row,
  Col,
  DeleteButton,
  ImageField,
} from '@pankod/refine-antd';

import { ImageDTO } from 'src/dtos';
import { useTable } from 'src/hooks/use-table';
import { dateColumn, idColumn, textColumn } from 'src/utils/list.utils';

export const StandardCoverList: FC = () => {
  const { tableProps, filterContext } = useTable<ImageDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({
              filterContext,
              field: 'origin',
              title: 'Cover',
              render: (value: string) => (
                <ImageField value={value} title="standard cover" />
              ),
            })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<ImageDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
