import { defineResource } from 'src/utils/define-resource.utils';

import { SolutionOutlined } from '@ant-design/icons';
import { AdminList } from './admin.list';
import { AdminShow } from './admin.show';
import { AdminCreate } from './admin.create';
import { AdminEdit } from './admin.edit';

export default defineResource({
  name: 'admins',
  create: AdminCreate,
  edit: AdminEdit,
  list: AdminList,
  show: AdminShow,
  icon: <SolutionOutlined />,
  parentName: 'admins-data',
});
