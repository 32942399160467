import userData from './user-data';
import user from './user';
import userRequest from './user-request';
import userHero from './user-hero';
import cms from './cms';
import country from './country';
import activity from './activity';
import setting from './setting';
import emergencyCall from './emergency-call';
import standardCover from './standard-cover';
import event from './event';
import ad from './ad';
import adStandardCover from './ad-standard-cover';
import product from './product';
import payment from './payment';
import userSubscription from './user-subscription';
import adminData from './admin-data';
import admin from './admin';
import other from './other';
import userReport from './user-report';
import userAppeal from './user-appeal';

export default [
  adminData,
  admin,
  userData,
  user,
  userHero,
  emergencyCall,
  event,
  ad,
  payment,
  userSubscription,
  cms,
  country,
  activity,
  setting,
  standardCover,
  adStandardCover,
  product,
  other,
  userRequest,
  userReport,
  userAppeal,
];
