import { BaseDTO } from 'src/dtos';

const entitiesToIds = (entities?: BaseDTO[]) =>
  entities ? entities.map(({ id }) => id) : undefined;

const getIdsFromValueProps = (entities?: BaseDTO[]) => ({
  value: entitiesToIds(entities),
});

const idsToEntities = (ids: string[]) => ids.map(id => ({ id }));
const getIdsFromEvent = (ids: string[]) => idsToEntities(ids);

export const manyToMany = {
  getValueProps: getIdsFromValueProps,
  getValueFromEvent: getIdsFromEvent,
};

const entityToId = (entity?: BaseDTO) => (entity ? entity.id : undefined);

const getIdFromValueProps = (entity?: BaseDTO) => ({
  value: entityToId(entity),
});

const getIdFromEvent = (id: string) => ({ id });

export const oneToOne = {
  getValueProps: getIdFromValueProps,
  getValueFromEvent: getIdFromEvent,
};
