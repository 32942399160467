import { defineResource } from 'src/utils/define-resource.utils';
import { HistoryOutlined } from '@ant-design/icons';
import { UserSubscriptionList } from './user-subscription.list';
import { UserSubscriptionShow } from './user-subscription.show';
import { UserSubscriptionCreate } from './user-subscription.create';
import { UserSubscriptionEdit } from './user-subscription.edit';

export default defineResource({
  name: 'user-subscriptions',
  list: UserSubscriptionList,
  show: UserSubscriptionShow,
  create: UserSubscriptionCreate,
  edit: UserSubscriptionEdit,
  icon: <HistoryOutlined />,
  parentName: 'users-data',
});
