import { Input } from '@pankod/refine-antd';
import { FC } from 'react';
import { HexColorPicker } from 'react-colorful';
import styles from './styles.module.css';

interface Props {
  value?: string;
  onChange?: (color: string) => void;
}

export const ColorInput: FC<Props> = ({ value, onChange }) => (
  <div className={styles['color-input']}>
    <HexColorPicker color={value} onChange={onChange} />
    <Input value={value} onChange={e => onChange?.(e.target.value)} />
  </div>
);
