import { defineResource } from 'src/utils/define-resource.utils';
import { AlertOutlined } from '@ant-design/icons';
import { EmergencyCallList } from './emergency-call.list';
import { EmergencyCallShow } from './emergency-call.show';

export default defineResource({
  name: 'emergency-calls',
  list: EmergencyCallList,
  show: EmergencyCallShow,
  icon: <AlertOutlined />,
  parentName: 'users-data',
});
