import { defineResource } from 'src/utils/define-resource.utils';

import { TeamOutlined } from '@ant-design/icons';

import { UserList } from './user.list';
import { UserEdit } from './user.edit';
import { UserShow } from './user.show';
import { UserCreate } from './user.create';

export default defineResource({
  name: 'users',
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: <TeamOutlined />,
  parentName: 'users-data',
});
