import { FC } from 'react';
import { useNavigation } from '@pankod/refine-core';

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const InternalLink: FC<Props> = props => {
  const { push } = useNavigation();
  const Anchor = 'a';

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    if (props.href) {
      push(props.href);
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return <Anchor {...props} onClick={clickHandler} />;
};
