import { FC } from 'react';
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from 'antd';
import { useLogin, useTranslate } from '@pankod/refine-core';

import {
  layoutStyles,
  containerStyles,
  titleStyles,
  imageContainer,
} from './styles';

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const translate = useTranslate();

  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} style={titleStyles}>
      {translate('pages.login.title', 'Sign in your account')}
    </Title>
  );

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <img
                src="/logo.svg"
                alt="Refine Logo"
                style={{
                  height: '15vh',
                }}
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={values => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label={translate('pages.login.username', 'Username')}
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    placeholder={translate('pages.login.username', 'Username')}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate('pages.login.password', 'Password')}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: '12px' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {translate('pages.login.remember', 'Remember me')}
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  {translate('pages.login.signin', 'Sign in')}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
