import { defineResource } from 'src/utils/define-resource.utils';
import { ShoppingOutlined } from '@ant-design/icons';

import { ProductList } from './product.list';
import { ProductShow } from './product.show';
import { ProductCreate } from './product.create';
import { ProductEdit } from './product.edit';

export default defineResource({
  name: 'products',
  list: ProductList,
  show: ProductShow,
  create: ProductCreate,
  edit: ProductEdit,
  icon: <ShoppingOutlined />,
  parentName: 'cms',
});
