import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { DateField, Show, Tag } from '@pankod/refine-antd';

import { UserRequestDTO } from 'src/dtos';
import { titleFormatter } from '../../utils/text.utils';
import { RequestTypeColors, RequestTypeEnum } from 'src/constants/enums';
import { ShowItem } from 'src/components/show-item';

export const UserRequestShow: FC = () => {
  const { queryResult } = useShow<UserRequestDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="Email">{record?.email}</ShowItem>

      <ShowItem title="User Name">{record?.name}</ShowItem>

      <ShowItem title="Message">{record?.message}</ShowItem>

      <ShowItem title="Type">
        <Tag color={RequestTypeColors[record?.type || RequestTypeEnum.OTHER]}>
          {record?.type ? titleFormatter(record.type) : '-'}
        </Tag>
      </ShowItem>

      <ShowItem title="Updated">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
