import { defineResource } from 'src/utils/define-resource.utils';
import { IdcardOutlined } from '@ant-design/icons';

import { UserHeroList } from './user-hero.list';
import { UserHeroShow } from './user-hero.show';
import { UserHeroCreate } from './user-hero.create';
import { UserHeroEdit } from './user-hero.edit';

export default defineResource({
  name: 'user-heroes',
  list: UserHeroList,
  create: UserHeroCreate,
  edit: UserHeroEdit,
  show: UserHeroShow,
  icon: <IdcardOutlined />,
  parentName: 'users-data',
});
