import { defineResource } from 'src/utils/define-resource.utils';

import { FieldTimeOutlined } from '@ant-design/icons';

import { EventList } from './event.list';
import { EventEdit } from './event.edit';
import { EventShow } from './event.show';

export default defineResource({
  name: 'events',
  list: EventList,
  edit: EventEdit,
  show: EventShow,
  icon: <FieldTimeOutlined />,
  parentName: 'users-data',
});
