import { FC, useRef } from 'react';
import {
  DatePicker,
  Edit,
  Form,
  Input,
  notification,
  RcFile,
  Select,
  useSelect,
} from '@pankod/refine-antd';
import { EventDTO, ImageDTO, isImageDTO, UserDTO } from 'src/dtos';
import { manyToMany, oneToOne } from 'src/utils/entity.utils';
import { ImageInput } from 'src/components/image-input';
import { EventStatus, EventType } from 'src/constants/enums';
import { useForm } from 'src/hooks/use-form';
import { BooleanInput } from 'src/components/boolean-input';
import { date } from 'src/utils/date.utils';
import { api, isNotOk } from 'src/services/http.service';
import { LocationFormInput } from 'src/components/location-form-input';

interface Values {
  id: string;
  cover?: RcFile | ImageDTO;
}

export const EventEdit: FC = () => {
  const fileRef = useRef<RcFile>();

  const { formProps, saveButtonProps, queryResult } = useForm<
    EventDTO,
    Values,
    unknown
  >({
    onFinish: values => {
      if (!isImageDTO(values.cover)) {
        const { cover, ...rest } = values;

        fileRef.current = cover;

        return { ...rest };
      }
    },
    afterSend: async () => {
      const coverFile = fileRef.current;
      const id = queryResult?.data?.data.id;

      if (!coverFile || !id) return;

      const cover = queryResult.data?.data.cover;

      const formData = new FormData();
      formData.append('file', coverFile);

      if (cover) {
        const { status } = await api.delete(`events/${id}/cover`);

        if (isNotOk(status)) {
          notification.error({ message: 'Error during cover removing' });
          return;
        }
      }

      const { status } = await api.post(`events/${id}/cover`, formData);

      if (isNotOk(status)) {
        notification.error({ message: 'Error during cover uploading' });
        return;
      }

      notification.success({ message: 'Cover successfully uploaded' });
    },
  });

  const { selectProps: creatorSelectProps } = useSelect<UserDTO>({
    resource: 'users',
    optionLabel: 'username',
  });

  /*
   * TODO: logic is different in user story
   * (in user story user can add only friends to participants)
   * maybe need change in future
   */
  const { selectProps: participantsSelectProps } = useSelect<UserDTO>({
    resource: 'users',
    optionLabel: 'username',
  });

  const { selectProps: activitiesSelectProps } = useSelect<EventDTO>({
    resource: 'activities',
    optionLabel: 'name',
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Id" name="id" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Creator Id"
          name="creatorId"
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[{ min: 5, max: 255, required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ min: 10, max: 500, required: true }]}
        >
          <Input.TextArea rows={4} autoSize={false} />
        </Form.Item>

        <Form.Item label="Cover" name="cover" rules={[{ required: false }]}>
          <ImageInput />
        </Form.Item>

        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select>
            <Select.Option value={EventType.PUBLIC}>Public</Select.Option>
            <Select.Option value={EventType.PRIVATE}>Private</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select disabled>
            <Select.Option value={EventStatus.CANCELED}>Canceled</Select.Option>
            <Select.Option value={EventStatus.CANCELED_BY_ADMIN}>
              Canceled By Admin
            </Select.Option>
            <Select.Option value={EventStatus.FINISHED}>Finished</Select.Option>
            <Select.Option value={EventStatus.RUNNING}>Running</Select.Option>
            <Select.Option value={EventStatus.WAITING}>Waiting</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Promoted" name="promoted">
          <BooleanInput />
        </Form.Item>

        <Form.Item
          label="Creator"
          name="creator"
          {...oneToOne}
          rules={[{ required: true }]}
        >
          <Select {...creatorSelectProps} disabled />
        </Form.Item>

        <Form.Item
          label="Participants"
          name="participants"
          {...manyToMany}
          rules={[{ required: false }]}
        >
          <Select {...participantsSelectProps} mode="multiple" />
        </Form.Item>

        <Form.Item
          label="Activities"
          name="activities"
          {...manyToMany}
          rules={[{ required: true }]}
        >
          <Select {...activitiesSelectProps} mode="multiple" />
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true }]}
        >
          <LocationFormInput />
        </Form.Item>

        <Form.Item label="Start Date" name="startDate" {...date}>
          <DatePicker disabled />
        </Form.Item>

        <Form.Item label="End Date" name="endDate" {...date}>
          <DatePicker disabled />
        </Form.Item>
      </Form>
    </Edit>
  );
};
