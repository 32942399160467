import { FC } from 'react';

import {
  List,
  Table,
  Space,
  ShowButton,
  Row,
  Col,
  DateField,
  DeleteButton,
  EditButton,
} from '@pankod/refine-antd';

import { ProductDTO } from 'src/dtos';
import { ProductType, ProductPlatform } from 'src/constants/enums';
import { RecoverButton } from 'src/components/recover-button';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';
import { useRole } from 'src/hooks/use-role';

export const ProductList: FC = () => {
  const { isSuperAdmin } = useRole();
  const { tableProps, filterContext } = useTable<ProductDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List canCreate={isSuperAdmin()}>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'title' })}
            {textColumn({ filterContext, field: 'storeProductId' })}
            {enumColumn({ filterContext, field: 'type', enum: ProductType })}
            {enumColumn({
              filterContext,
              field: 'platform',
              enum: ProductPlatform,
              render: value => (value ? value : 'not defined'),
            })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}
            {dateColumn({
              filterContext,
              field: 'deletedAt',
              render: value =>
                value ? <DateField format="LLL" value={value} /> : 'null',
            })}

            <Table.Column<ProductDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  {isSuperAdmin() && (
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  )}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  {isSuperAdmin() ? (
                    record.deletedAt === null ? (
                      <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                      />
                    ) : (
                      <RecoverButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                      />
                    )
                  ) : null}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
