import { Divider, Empty } from '@pankod/refine-antd';
import { FC } from 'react';
import { NestedContainer } from 'src/components/nested-container';
import { ShowItem } from 'src/components/show-item';
import { Status } from 'src/components/status';
import { DetailItem } from 'src/dtos';

interface Props {
  details: DetailItem[];
}

export const DetailsShow: FC<Props> = ({ details }) => (
  <NestedContainer>
    {details.map((detail, i) => (
      <div key={i}>
        {i !== 0 && <Divider />}

        <ShowItem title="Type">
          <Status value={detail.type} />
        </ShowItem>

        <ShowItem title="Key">{detail.key}</ShowItem>

        <ShowItem title="Body Key">
          {detail.bodyKey ? detail.bodyKey : <Empty />}
        </ShowItem>
      </div>
    ))}
  </NestedContainer>
);
