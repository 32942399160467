interface ThumbnailsDTO {
  '250x250': string;
  '1284x846': string;
  '640x420': string;
  '1284x1964': string;
  '640x980': string;
}

export interface ImageDTO {
  id: string;
  origin: string;
  thumbnails?: ThumbnailsDTO;
}

const isObject = (
  object?: unknown,
): object is Record<string | number | symbol, unknown> => {
  return typeof object === 'object' || object !== null;
};

export const isImageDTO = (object?: unknown): object is ImageDTO => {
  if (!isObject(object)) return false;

  return typeof object?.origin === 'string' && typeof object?.id === 'string';
};
