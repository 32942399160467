import { defineResource } from 'src/utils/define-resource.utils';
import { SolutionOutlined } from '@ant-design/icons';

import { UserAppealList } from './user-appeal.list';
import { UserAppealShow } from './user-appeal.show';

export default defineResource({
  name: 'user-appeals',
  list: UserAppealList,
  show: UserAppealShow,
  icon: <SolutionOutlined />,
  parentName: 'other',
  options: {
    label: 'Appeals',
  },
});
