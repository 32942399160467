import { FC, useState } from 'react';
import { Button, Form, Input, Modal, notification } from '@pankod/refine-antd';
import { StopOutlined } from '@ant-design/icons';
import { api, isNotOk } from 'src/services/http.service';
import { useInvalidate } from '@pankod/refine-core';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const BanButton: FC<Props> = ({ hideText, size, recordItemId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const invalidate = useInvalidate();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const clear = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const handleFinish = async ({ reason }: { reason: string }) => {
    const { status } = await api.post(`users/${recordItemId}/ban`, { reason });

    if (isNotOk(status)) {
      return notification.error({
        message: 'Error',
        description: 'During ban',
      });
    }

    notification.success({ message: 'Success', description: 'Banned' });

    invalidate({
      resource: 'users',
      id: recordItemId,
      invalidates: ['resourceAll'],
    });
    clear();
  };

  return (
    <>
      <Button size={size} icon={<StopOutlined />} onClick={showModal}>
        {!hideText && 'Ban'}
      </Button>

      <Modal
        title="Ban"
        open={isModalVisible}
        okText="Submit"
        onOk={handleOk}
        onCancel={clear}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[{ required: true }, { type: 'string', min: 1, max: 2000 }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
