import { FC, Fragment } from 'react';
import { useShow } from '@pankod/refine-core';
import {
  Show,
  DateField,
  Tabs,
  Row,
  Col,
  Divider,
  Card,
  Space,
  Image,
  Empty,
  TabsProps,
} from '@pankod/refine-antd';

import { UserDTO } from 'src/dtos';
import { ShowItem } from 'src/components/show-item';
import { ShowBoolean } from 'src/components/show-boolean';
import { Status } from 'src/components/status';
import { ReferenceOne } from 'src/components/reference-one';
import { availabilityCalendarKeys } from 'src/constants/availability-calendar';
import { UserAvailabilityCalendarDTO } from 'src/dtos/user-availability-calendar.dto';
import { notificationSettings } from 'src/constants/notification-settings';
import { socialLinks } from 'src/constants/social-links';
import { isHasMetadata } from './register-meta/register-meta.edit';
import { RegisterMetaShow } from './register-meta/register-meta.show';
import { Location } from 'src/components/location';

export const UserShow: FC = () => {
  const { queryResult } = useShow<UserDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const bio = !record?.bio ? <Empty /> : record?.bio;
  const mainPhoto = record?.mainPhotoId
    ? record?.photos.find(img => img.id === record.mainPhotoId)
    : null;

  const items: TabsProps['items'] = [
    {
      // Main Tab
      label: 'Main',
      key: '1',
      children: (
        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <ShowItem title="Id">{record?.id}</ShowItem>

            <ShowItem title="Username">{record?.username}</ShowItem>

            <ShowItem title="Fullname">{record?.fullname}</ShowItem>

            <ShowItem title="Bio">{bio}</ShowItem>

            <Divider />

            <ShowItem title="Gender">
              {record && <Status value={record.gender} />}
            </ShowItem>

            <ShowItem title="Birthday">
              <DateField value={record?.birthday} />
            </ShowItem>

            <ShowItem title="Hide Birthday">
              {record && <ShowBoolean value={record.hideBirthday} />}
            </ShowItem>

            <Divider />

            <ShowItem title="Phone">{record?.phone}</ShowItem>

            <ShowItem title="Phone Verified">
              {record && <ShowBoolean value={record.phoneVerified} />}
            </ShowItem>

            <ShowItem title="Email">{record?.email}</ShowItem>

            <ShowItem title="Email Verified">
              {record && <ShowBoolean value={record.emailVerified} />}
            </ShowItem>

            <Divider />

            <ShowItem title="Register Method">
              {record && <Status value={record?.registerMethod} />}
            </ShowItem>

            <ShowItem title="Register Identity">
              {record?.registerIdentity ? record?.registerIdentity : <Empty />}
            </ShowItem>

            <ShowItem title="Register Meta">
              {record && isHasMetadata(record.registerMethod) ? (
                <RegisterMetaShow {...record} />
              ) : (
                <Empty />
              )}
            </ShowItem>
          </Col>

          <Col lg={8} xs={24}>
            <ShowItem title="Updated At">
              <DateField format="LLL" value={record?.updatedAt} />
            </ShowItem>

            <ShowItem title="Created At">
              <DateField format="LLL" value={record?.createdAt} />
            </ShowItem>

            <Divider />

            <ShowItem title="Terms Accepted">
              {record && <ShowBoolean value={record.termsAccepted} />}
            </ShowItem>

            <ShowItem title="Privacy Accepted">
              {record && <ShowBoolean value={record.privacyAccepted} />}
            </ShowItem>

            <Divider />

            <ShowItem title="Occupancy Scale">
              {record?.occupancyScale}
            </ShowItem>

            <ShowItem title="Onboarding Step">
              {record && <Status value={record.onboardingStep} />}
            </ShowItem>

            <ShowItem title="Profile Visible">
              {record && <Status value={record.profileVisible} />}
            </ShowItem>

            <Divider />

            <ShowItem title="Country">{record?.country}</ShowItem>

            <ShowItem title="State">
              {record?.state.length ? record.state : <Empty />}
            </ShowItem>

            <ShowItem title="City">{record?.city}</ShowItem>

            <Divider />

            <ShowItem title="Language">
              {record && <Status value={record.language} />}
            </ShowItem>

            <ShowItem title="Units">
              {record && <Status value={record.units} />}
            </ShowItem>

            <ShowItem title="Color Mode">
              {record && <Status value={record.colorMode} />}
            </ShowItem>

            <Divider />

            <ShowItem title="Location">
              {record?.location ? (
                <Location location={record.location} />
              ) : (
                <Empty />
              )}
            </ShowItem>

            <Divider />

            <ShowItem title="Is Banned">
              {record && <ShowBoolean value={!!record.ban} />}
            </ShowItem>

            <ShowItem title="Ban Reason">
              {record?.ban ? record.ban.reason : <Empty />}
            </ShowItem>

            <ShowItem title="Ban Created At">
              {record?.ban ? (
                <DateField format="LLL" value={record?.ban.createdAt} />
              ) : (
                <Empty />
              )}
            </ShowItem>

            <Divider />

            <ShowItem title="Is Introduction">
              {record && <ShowBoolean value={record.isIntroduction} />}
            </ShowItem>
          </Col>
        </Row>
      ),
    },
    {
      // Photos Tab
      label: 'Photos',
      key: '2',
      children: (
        <>
          <ShowItem title="Main Photo">
            {mainPhoto && <Image src={mainPhoto.origin} alt="main photo" />}

            {!mainPhoto && <Empty />}
          </ShowItem>

          <ShowItem title="Photos">
            {record &&
              record.photos.map(({ id, origin }, i) => (
                <Fragment key={id}>
                  {i !== 0 && <Divider />}
                  <Image src={origin} alt="user-photo" />
                </Fragment>
              ))}

            {record && record.photos.length === 0 && <Empty />}
          </ShowItem>
        </>
      ),
    },
    {
      // Activities Tab
      label: 'Activities',
      key: '3',
      children: (
        <>
          {record &&
            record.activities.map(({ level, activity, activityId, id }, i) => (
              <Fragment key={id}>
                {i !== 0 && <Divider />}

                <ShowItem title="Activity">
                  <ReferenceOne
                    label={activity.name}
                    resource="activities"
                    id={activityId}
                  />
                </ShowItem>

                <ShowItem title="Level">
                  <Status value={level} />
                </ShowItem>
              </Fragment>
            ))}

          {record && record.activities.length === 0 && <Empty />}
        </>
      ),
    },
    {
      // Other Tab
      label: 'Other',
      key: '4',
      children: (
        <Row gutter={[16, 16]}>
          <Col lg={12} xs={24}>
            <Card title="Availability Calendar">
              {availabilityCalendarKeys.map(({ value, label }, i) => (
                <Fragment key={value}>
                  {i !== 0 && <Divider />}

                  <ShowItem title={`${label} From`}>
                    {record &&
                      record.availabilityCalendar[
                        value as keyof UserAvailabilityCalendarDTO
                      ]?.from}
                  </ShowItem>

                  <ShowItem title={`${label} To`}>
                    {record &&
                      record.availabilityCalendar[
                        value as keyof UserAvailabilityCalendarDTO
                      ]?.to}
                  </ShowItem>
                </Fragment>
              ))}
            </Card>
          </Col>
          <Col lg={12} xs={24}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              <Card title="Notification Setting">
                {notificationSettings.map(({ value, label }) => (
                  <ShowItem key={value} title={label}>
                    {record && (
                      <ShowBoolean
                        value={record.notificationSettings?.[value]}
                      />
                    )}
                  </ShowItem>
                ))}
              </Card>

              <Card title="Social Links">
                {socialLinks.map(({ value, label }) => (
                  <ShowItem key={value} title={label}>
                    {record?.socialLinks?.[value] ?? <Empty />}
                  </ShowItem>
                ))}
              </Card>
            </Space>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Show isLoading={isLoading}>
      <Tabs items={items} />
    </Show>
  );
};
