import 'src/styles/antd.less';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { FC } from 'react';
import { Refine } from '@pankod/refine-core';

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from 'src/components/layout';

import { DashboardPage } from 'src/pages/dashboard';
import { LoginPage } from 'src/pages/login';

import { customNestJsCrudDataProvider } from './providers/custom-nestjs-crud-data.provider';
import { authProvider } from './providers/auth.provider';
import routerProvider from '@pankod/refine-react-router-v6';
import { notificationProvider } from '@pankod/refine-antd';

import resources from './resources';

import { API_URL } from 'src/constants/config';

export const App: FC = () => (
  <Refine
    DashboardPage={DashboardPage}
    routerProvider={routerProvider}
    notificationProvider={notificationProvider}
    dataProvider={customNestJsCrudDataProvider(API_URL)}
    authProvider={authProvider}
    LoginPage={LoginPage}
    resources={resources}
    Title={Title}
    Header={Header}
    Sider={Sider}
    Footer={Footer}
    Layout={Layout}
    OffLayoutArea={OffLayoutArea}
    options={{ reactQuery: { devtoolConfig: false } }}
  />
);
