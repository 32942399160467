import { defineResource } from 'src/utils/define-resource.utils';

import { ReconciliationOutlined } from '@ant-design/icons';

import { AdList } from './ad.list';
import { AdShow } from './ad.show';

export default defineResource({
  name: 'ads',
  list: AdList,
  show: AdShow,
  icon: <ReconciliationOutlined />,
  parentName: 'users-data',
});
