import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { DateField, Show } from '@pankod/refine-antd';

import { SettingDTO } from 'src/dtos';
import { useRole } from 'src/hooks/use-role';
import { ShowItem } from 'src/components/show-item';

export const SettingShow: FC = () => {
  const { isSuperAdmin } = useRole();
  const { queryResult } = useShow<SettingDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      canEdit={isSuperAdmin()}
      canDelete={isSuperAdmin()}
    >
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="Key">{record?.key}</ShowItem>

      <ShowItem title="Value">{record?.value}</ShowItem>

      <ShowItem title="Updated">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
