import { FC } from 'react';
import { Button, notification, Popconfirm } from '@pankod/refine-antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { api, isNotOk } from 'src/services/http.service';
import { useInvalidate } from '@pankod/refine-core';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const PardonButton: FC<Props> = ({ hideText, size, recordItemId }) => {
  const invalidate = useInvalidate();

  const pardon = async () => {
    const { status } = await api.post(`users/${recordItemId}/pardon`);

    if (isNotOk(status)) {
      return notification.error({
        message: 'Error',
        description: 'During pardon',
      });
    }

    notification.success({ message: 'Success', description: 'Pardoned' });
    invalidate({
      resource: 'users',
      id: recordItemId,
      invalidates: ['resourceAll'],
    });
  };

  return (
    <Popconfirm key="pardon" title="Pardon" onConfirm={pardon}>
      <Button size={size} icon={<CheckCircleOutlined />}>
        {!hideText && 'Pardon'}
      </Button>
    </Popconfirm>
  );
};
