import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField } from '@pankod/refine-antd';

import { AdminDTO } from 'src/dtos';
import { Status } from 'src/components/status';
import { ShowItem } from 'src/components/show-item';

export const AdminShow: FC = () => {
  const { queryResult } = useShow<AdminDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="Username">{record?.username}</ShowItem>

      <ShowItem title="Role">
        {record && <Status value={record.role} />}
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
