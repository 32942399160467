import { createRoot } from 'react-dom/client';

import { App } from './app';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// without StrictMode because react-beautiful-dnd doesn't work with react 18 StrictMode
// https://github.com/atlassian/react-beautiful-dnd/issues/2393
root.render(<App />);
