import { FC } from 'react';

import { List, Table, Space, ShowButton, Row, Col } from '@pankod/refine-antd';

import { UserAppealStatus } from 'src/constants/enums';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
  trimBigText,
} from 'src/utils/list.utils';
import { ReferenceOne } from 'src/components/reference-one';
import { ChangeStatusButton } from './change-status/change-status.button';
import { UserAppealDTO } from 'src/dtos';

export const UserAppealList: FC = () => {
  const { tableProps, filterContext } = useTable<UserAppealDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {idColumn({
              filterContext,
              field: 'userId',
              title: 'User ID',
              render: (_, record: UserAppealDTO) => (
                <>
                  {!record.user && 'Null'}
                  {record.user && (
                    <ReferenceOne
                      resource="users"
                      id={record.user?.id}
                      label={record?.user.username}
                    />
                  )}
                </>
              ),
            })}
            {idColumn({
              filterContext,
              field: 'originalUserId',
              title: 'Original User ID',
              render: (_, record: UserAppealDTO) => (
                <>
                  {!record.originalUserId && 'Null'}
                  {record.originalUserId && record.originalUserId}
                </>
              ),
            })}
            {textColumn({
              filterContext,
              field: 'email',
            })}
            {textColumn({
              filterContext,
              field: 'message',
              render: trimBigText,
            })}
            {enumColumn({
              filterContext,
              field: 'status',
              enum: UserAppealStatus,
            })}
            {textColumn({
              filterContext,
              field: 'adminComment',
              title: 'Admin Comment',
              render: (_, record: UserAppealDTO) => (
                <>
                  {!record.adminComment && 'Null'}
                  {record.adminComment && record.adminComment}
                </>
              ),
            })}
            {dateColumn({ filterContext, field: 'createdAt' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}

            <Table.Column<UserAppealDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ChangeStatusButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
