import { FC } from 'react';
import { Edit, Form, Input, useForm } from '@pankod/refine-antd';

import { CountryDTO } from 'src/dtos';
import { BooleanInput } from 'src/components/boolean-input';

export const CountryEdit: FC = () => {
  const { formProps, saveButtonProps } = useForm<CountryDTO>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Phone Code"
          name="phoneCode"
          rules={[
            {
              required: true,
              type: 'number',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label="Active" name="active">
          <BooleanInput />
        </Form.Item>
      </Form>
    </Edit>
  );
};
