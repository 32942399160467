import { FC, PropsWithChildren } from 'react';
import { Typography } from '@pankod/refine-antd';

const { Title, Text } = Typography;

interface Props {
  title: string;
}

export const ShowItem: FC<PropsWithChildren<Props>> = ({ title, children }) => (
  <>
    <Title level={5}>{title}</Title>
    <Text>{children}</Text>
  </>
);
