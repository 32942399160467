import {
  FilterDropdown,
  FilterDropdownProps,
  Input,
  Radio,
  SelectProps,
  DatePicker,
} from '@pankod/refine-antd';
import { CrudFilters } from '@pankod/refine-core';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { TableSelect } from 'src/components/table-select';
import { camelCaseToLabel } from './text.utils';
import { Dayjs } from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { noop } from './fp.utils';
import { clsx } from './clsx.utils';

export type Operator =
  | 'eq'
  | 'ne'
  | 'lt'
  | 'gt'
  | 'lte'
  | 'gte'
  | 'in'
  | 'nin'
  | 'contains'
  | 'ncontains'
  | 'containss'
  | 'ncontainss'
  | 'between'
  | 'nbetween'
  | 'null'
  | 'nnull';

export type SetFilter = (
  field: string,
  operator: Operator,
  value: string,
) => void;
export type RemoveFilter = (field: string, operator: Operator) => void;
export type HasFilter = (field: string, operator?: Operator) => boolean;
export type ApplyFilters = () => void;

export interface FilterContext {
  filters: CrudFilters;
  setFilter: SetFilter;
  removeFilter: RemoveFilter;
  hasFilter: HasFilter;
  applyFilters: ApplyFilters;
  setAndApplyFilter: SetFilter;
}

export const filterDropdownIcon = (
  { hasFilter }: FilterContext,
  field: string,
) => {
  return () => (
    <SearchOutlined
      className={clsx('filter-icon', hasFilter(field) && 'active')}
    />
  );
};

// exists to prevent table column sort on enter press
const Form: FC<PropsWithChildren<{ onSubmit: () => void }>> = ({
  children,
  onSubmit,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!formRef.current) return;

    formRef.current.onkeydown = e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
      }
    };

    return () => {
      if (!formRef.current) return;

      formRef.current.onkeydown = null;
    };
  }, [onSubmit]);

  return <form ref={formRef}>{children}</form>;
};

const Dropdown: FC<PropsWithChildren<FilterDropdownProps>> = ({
  children,
  ...props
}) => (
  <FilterDropdown {...props}>
    {/* wrapper on input exists to consume value and onChange */}
    <Form onSubmit={props.confirm}>{children}</Form>
  </FilterDropdown>
);

interface UseDropdownOptions {
  props: FilterDropdownProps;
  applyFilters: ApplyFilters;
  isEmpty: () => boolean;
  setFilters: () => void;
  removeFilters: () => void;
  removeValues: () => void;
}

const patchDropdownProps = ({
  props,
  applyFilters,
  isEmpty,
  setFilters,
  removeFilters,
  removeValues,
}: UseDropdownOptions) => {
  const confirm = () => {
    if (isEmpty()) {
      props?.clearFilters?.();

      removeFilters();
    } else {
      setFilters();
    }

    applyFilters();
    props.confirm();
  };

  const clearFilters = () => {
    props?.clearFilters?.();
    removeValues();
  };

  const dropdownProps = {
    ...props,
    confirm,
    clearFilters,
    setSelectedKeys: noop,
  };

  return {
    confirm,
    clearFilters,
    dropdownProps,
  };
};

export const textDropdown = (
  { setFilter, removeFilter, applyFilters }: FilterContext,
  field: string,
  operator: Operator = 'contains',
) => {
  return (props: FilterDropdownProps) => {
    const [value, setValue] = useState('');
    const { dropdownProps } = patchDropdownProps({
      props,
      applyFilters,
      isEmpty: () => value === '',
      setFilters: () => setFilter(field, operator, value),
      removeFilters: () => removeFilter(field, operator),
      removeValues: () => setValue(''),
    });

    return (
      <Dropdown {...dropdownProps}>
        <Input value={value} onChange={e => setValue(e.target.value)} />
      </Dropdown>
    );
  };
};

export const dateFilterDropdown = (
  { setFilter, removeFilter, applyFilters }: FilterContext,
  field: string,
) => {
  return (props: FilterDropdownProps) => {
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);

    const handleFilter = (value: Dayjs | null, operator: Operator) => {
      if (value === null) return;

      setFilter(field, operator, value.toISOString());
    };

    const { dropdownProps } = patchDropdownProps({
      props,
      applyFilters,
      isEmpty: () => from === null && to === null,
      setFilters: () => {
        handleFilter(from, 'gte');
        handleFilter(to, 'lte');
      },
      removeFilters: () => {
        removeFilter(field, 'gte');
        removeFilter(field, 'lte');
      },
      removeValues: () => {
        setFrom(null);
        setTo(null);
      },
    });

    return (
      <Dropdown {...dropdownProps}>
        <div>
          <div>From:</div>
          <DatePicker value={from} onChange={value => setFrom(value)} />
        </div>

        <div className="padding-small" />

        <div>
          <div>To:</div>
          <DatePicker
            value={to}
            onChange={value => setTo(value)}
            disabledDate={date => {
              if (from === null) return false;

              return from.valueOf() > date.valueOf();
            }}
          />
        </div>
      </Dropdown>
    );
  };
};

export const enumFilterDropdown = <E extends Record<string, string>>(
  { setFilter, removeFilter, applyFilters }: FilterContext,
  field: string,
  enumType: E,
  formatter: (value: string) => string = camelCaseToLabel,
) => {
  return (props: FilterDropdownProps) => {
    const [value, setValue] = useState<E[keyof E] | null>(null);
    const { dropdownProps } = patchDropdownProps({
      props,
      applyFilters,
      isEmpty: () => value === null,
      setFilters: () => {
        if (value !== null) {
          setFilter(field, 'eq', value);
        }
      },
      removeFilters: () => removeFilter(field, 'eq'),
      removeValues: () => setValue(null),
    });

    return (
      <Dropdown {...dropdownProps}>
        <Radio.Group
          options={Object.values(enumType).map(value => ({
            label: formatter(value),
            value,
          }))}
          onChange={e => setValue(e.target.value)}
          value={value}
        />
      </Dropdown>
    );
  };
};

export const relationSelectDropdown = (
  { setFilter, applyFilters, removeFilter }: FilterContext,
  field: string,
  selectProps: SelectProps<{ value: string; label: string }>,
) => {
  return (props: FilterDropdownProps) => {
    const [selected, setSelected] = useState<string | null>(null);
    const { dropdownProps } = patchDropdownProps({
      props,
      applyFilters,
      isEmpty: () => selected === null,
      setFilters: () => {
        if (selected !== null) {
          setFilter(field, 'eq', selected);
        }
      },
      removeFilters: () => removeFilter(field, 'eq'),
      removeValues: () => setSelected(null),
    });

    return (
      <Dropdown {...dropdownProps}>
        <TableSelect
          {...selectProps}
          onChange={value => setSelected(value as unknown as string)}
          value={selected as unknown as { value: string; label: string }}
        />
      </Dropdown>
    );
  };
};

export const booleanDropdown = (
  { setFilter, applyFilters, removeFilter }: FilterContext,
  field: string,
) => {
  return (props: FilterDropdownProps) => {
    const [value, setValue] = useState<boolean | null>(null);
    const { dropdownProps } = patchDropdownProps({
      props,
      applyFilters,
      isEmpty: () => value === null,
      setFilters: () => {
        if (value !== null) {
          setFilter(field, 'eq', value.toString());
        }
      },
      removeFilters: () => {
        removeFilter(field, 'eq');
      },
      removeValues: () => setValue(null),
    });

    return (
      <Dropdown {...dropdownProps}>
        <Radio.Group
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          onChange={e => setValue(e.target.value)}
          value={value}
        />
      </Dropdown>
    );
  };
};
