import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, Collapse, ImageField } from '@pankod/refine-antd';

import { AdDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { ShowBoolean } from 'src/components/show-boolean';
import { Status } from 'src/components/status';
import { ShowItem } from 'src/components/show-item';
import { formatHistoryDate } from 'src/utils/date.utils';
import { Location } from 'src/components/location';

export const AdShow: FC = () => {
  const { queryResult } = useShow<AdDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="Title">{record?.title}</ShowItem>

      <ShowItem title="Description">{record?.description}</ShowItem>

      <ShowItem title="External Link">
        <a target="_blank" rel="noopener noreferrer">
          {record?.externalLink}
        </a>
      </ShowItem>

      <ShowItem title="Location">
        {record?.location && <Location location={record?.location} />}
      </ShowItem>

      <ShowItem title="Creator">
        {record && (
          <ReferenceOne
            resource="users"
            id={record.creator.id}
            label={record.creator.username}
          />
        )}
      </ShowItem>

      {record?.event && (
        <ShowItem title="Event">
          <ReferenceOne
            key={record.event.id}
            resource="events"
            id={record.event.id}
            label={record.event.title}
          />
        </ShowItem>
      )}

      {record?.cover && (
        <ShowItem title="Cover">
          <ImageField value={record.cover.origin} alt="ad cover" />
        </ShowItem>
      )}

      <ShowItem title="Targets">
        {record &&
          record.targets.map(({ id, name }) => (
            <ReferenceOne key={id} resource="activities" id={id} label={name} />
          ))}
      </ShowItem>

      {record?.history && (
        <ShowItem title="History">
          <Collapse>
            {record.history.map(
              (
                { impressions, clicks, budget, days, startDate, endDate },
                i,
              ) => (
                <Collapse.Panel
                  header={`${formatHistoryDate(
                    startDate,
                  )} - ${formatHistoryDate(endDate)}`}
                  key={i}
                >
                  <ShowItem title="Impressions">{impressions}</ShowItem>
                  <ShowItem title="Clicks">{clicks}</ShowItem>
                  <ShowItem title="Days">{days}</ShowItem>
                  <ShowItem title="Budget">{budget}</ShowItem>
                  <ShowItem title="Start Date">
                    <DateField format="LLL" value={startDate} />
                  </ShowItem>

                  <ShowItem title="End Date">
                    <DateField format="LLL" value={endDate} />
                  </ShowItem>
                </Collapse.Panel>
              ),
            )}
          </Collapse>
        </ShowItem>
      )}

      <ShowItem title="Type">
        {record && <Status value={record.type} />}
      </ShowItem>

      <ShowItem title="Status">
        {record && <Status value={record.status} />}
      </ShowItem>

      <ShowItem title="Active">
        {record && <ShowBoolean value={record.active} />}
      </ShowItem>

      <ShowItem title="BoostedAt">
        <DateField format="LLL" value={record?.boostedAt} />
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
