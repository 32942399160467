import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, DateField, ImageField } from '@pankod/refine-antd';

import { EventDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { Location } from 'src/components/location';
import { ShowBoolean } from 'src/components/show-boolean';
import { Status } from 'src/components/status';
import { ShowItem } from 'src/components/show-item';

export const EventShow: FC = () => {
  const { queryResult } = useShow<EventDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="Id">{record?.id}</ShowItem>

      <ShowItem title="Title">{record?.title}</ShowItem>

      <ShowItem title="Description">{record?.description}</ShowItem>

      <ShowItem title="Creator">
        {record && (
          <ReferenceOne
            resource="users"
            id={record.creator.id}
            label={record.creator.username}
          />
        )}
      </ShowItem>

      <ShowItem title="Location">
        {record && <Location location={record.location} />}
      </ShowItem>

      {record?.cover && (
        <ShowItem title="Cover">
          <ImageField value={record.cover.origin} alt="event cover" />
        </ShowItem>
      )}

      <ShowItem title="Promoted">
        {record && <ShowBoolean value={record.promoted} />}
      </ShowItem>

      <ShowItem title="Type">
        {record && <Status value={record.type} />}
      </ShowItem>

      <ShowItem title="Status">
        {record && <Status value={record.status} />}
      </ShowItem>

      <ShowItem title="Participants">
        {record && record.participants.length === 0 && 'None'}
        {record &&
          record.participants.map(({ id, username }) => (
            <ReferenceOne key={id} resource="users" id={id} label={username} />
          ))}
      </ShowItem>

      <ShowItem title="Activities">
        {record &&
          record.activities.map(({ id, name }) => (
            <ReferenceOne key={id} resource="activities" id={id} label={name} />
          ))}
      </ShowItem>

      <ShowItem title="Start Date">
        <DateField format="LLL" value={record?.startData} />
      </ShowItem>

      <ShowItem title="End Date">
        <DateField format="LLL" value={record?.endData} />
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
