import { FC } from 'react';

import { List, Table, Space, ShowButton, Row, Col } from '@pankod/refine-antd';

import { EmergencyCallDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { EmergencyCallStatus } from 'src/constants/enums';
import { useTable } from 'src/hooks/use-table';
import { dateColumn, enumColumn, idColumn } from 'src/utils/list.utils';

export const EmergencyCallList: FC = () => {
  const { tableProps, filterContext } = useTable<EmergencyCallDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {idColumn({
              filterContext,
              field: 'userId',
              title: 'User ID',
              render: (_, record: EmergencyCallDTO) => (
                <ReferenceOne
                  resource="users"
                  id={record.user.id}
                  label={record.user.username}
                />
              ),
            })}
            {enumColumn({
              filterContext,
              field: 'status',
              enum: EmergencyCallStatus,
            })}
            {dateColumn({ filterContext, field: 'startDate' })}
            {dateColumn({ filterContext, field: 'endDate' })}
            {dateColumn({ filterContext, field: 'createdAt' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}

            <Table.Column<EmergencyCallDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
