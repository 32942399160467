import { NotificationSettingsKeys } from './enums';

export const notificationSettings = [
  {
    value: NotificationSettingsKeys.SHOW_NEW_MESSAGES,
    label: 'Show new messages',
  },
  {
    value: NotificationSettingsKeys.SHOW_EVENT_INVITATIONS,
    label: 'Show event invitations',
  },
  {
    value: NotificationSettingsKeys.SHOW_UPCOMING_EVENTS,
    label: 'Show upcoming events',
  },
  {
    value: NotificationSettingsKeys.SHOW_NEW_LIKES,
    label: 'Show new likes',
  },
  {
    value: NotificationSettingsKeys.SHOW_NEW_MATCH,
    label: 'Show new match',
  },
];
