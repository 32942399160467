import { SocialLinksKeys } from './enums';

export const socialLinks = [
  {
    value: SocialLinksKeys.FACEBOOK,
    label: 'Facebook',
  },
  {
    value: SocialLinksKeys.INSTAGRAM,
    label: 'Instagram',
  },
  {
    value: SocialLinksKeys.STRAVA,
    label: 'Strava',
  },
];
