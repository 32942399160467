import { FC } from 'react';
import { Edit, Form, Input, useForm } from '@pankod/refine-antd';

import { SettingDTO } from 'src/dtos';

export const SettingEdit: FC = () => {
  const { formProps, saveButtonProps } = useForm<SettingDTO>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Key"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
          tooltip="Unique setting key"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Value"
          name="value"
          rules={[
            {
              required: true,
            },
          ]}
          tooltip="Setting value"
        >
          <Input.TextArea rows={20} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
