import { defineResource } from 'src/utils/define-resource.utils';

import { GlobalOutlined } from '@ant-design/icons';

import { CountryList } from './country.list';
import { CountryCreate } from './country.create';
import { CountryEdit } from './country.edit';
import { CountryShow } from './country.show';

export default defineResource({
  name: 'countries',
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
  show: CountryShow,
  icon: <GlobalOutlined />,
  parentName: 'cms',
});
