import { FC } from 'react';
import { NestedContainer } from 'src/components/nested-container';
import { ShowItem } from 'src/components/show-item';
import { RegisterMethod } from 'src/constants/enums';
import {
  AppleRegisterMeta,
  GoogleRegisterMeta,
  FacebookRegisterMeta,
  UserDTO,
} from 'src/dtos';

const Apple: FC<{ meta: AppleRegisterMeta }> = ({ meta }) => (
  <NestedContainer>
    <ShowItem title="Apple Id Token">{meta.appleIdToken}</ShowItem>
  </NestedContainer>
);

const Google: FC<{ meta: GoogleRegisterMeta }> = ({ meta }) => (
  <NestedContainer>
    <ShowItem title="Google Id Token">{meta.googleIdToken}</ShowItem>
  </NestedContainer>
);

const Facebook: FC<{ meta: FacebookRegisterMeta }> = ({ meta }) => (
  <NestedContainer>
    <ShowItem title="Facebook Access Token">
      {meta.facebookAccessToken}
    </ShowItem>
  </NestedContainer>
);

const Unknown: FC = () => <div>Unknown</div>;

export const RegisterMetaShow: FC<UserDTO> = user => {
  switch (user.registerMethod) {
    case RegisterMethod.APPLE:
      return <Apple meta={user.registerMeta} />;
    case RegisterMethod.GOOGLE:
      return <Google meta={user.registerMeta} />;
    case RegisterMethod.FACEBOOK:
      return <Facebook meta={user.registerMeta} />;
    default:
      return <Unknown />;
  }
};
