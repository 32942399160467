import { AdminDTO } from 'src/dtos';
import { getAuth, setAuth, Tokens } from 'src/stores/auth.store';
import { api, Response } from './http.service';

interface Header {
  alg: string;
  typ: string;
}

interface Payload {
  id: string;
  aud: string;
  sub: string;
  iat: number; // seconds
  exp: number; // seconds
  iss: string;
}

const parseJWT = (token: string) => {
  const [headerBase64, payloadBase64] = token.split('.');
  const [headerJson, payloadJson] = [headerBase64, payloadBase64].map(base64 =>
    window.atob(base64),
  );
  const header: Header = JSON.parse(headerJson);
  const payload: Payload = JSON.parse(payloadJson);

  return { header, payload };
};

const checkJwtTokenExp = (token: string) => {
  const { payload } = parseJWT(token);
  const { exp } = payload;
  const expiresAt = new Date(exp * 1000);

  return expiresAt.getTime() > Date.now();
};

export const getTokens = () => {
  const tokens = getAuth();

  if (!tokens) {
    throw new Error('Wrong auth');
  }

  return tokens;
};

export const exchangeRefreshToken = async (refreshToken: string) => {
  const { data: body, status } = await api.post<Response<Tokens>>(
    'auth/refresh-token',
    { refreshToken },
  );

  if (status >= 400 && status < 500) {
    throw new Error('wrong refresh-token');
  }

  if (status >= 500) {
    throw new Error('internal error');
  }

  return body.data;
};

export const signin = async (username: string, password: string) => {
  const { data: body, status } = await api.post<Response<Tokens>>(
    'auth/signin',
    { username, password },
  );

  if (status >= 400 && status < 500) {
    throw new Error('incorrect username or password');
  }

  if (status >= 500) {
    throw new Error('internal error');
  }

  setAuth(body.data);
};

export const checkAuth = async () => {
  const { accessToken, refreshToken } = getTokens();

  const isAccessTokenValid = checkJwtTokenExp(accessToken);
  if (isAccessTokenValid) return;

  const isRefreshTokenValid = checkJwtTokenExp(refreshToken);
  if (!isRefreshTokenValid) {
    throw new Error('Wrong auth');
  }

  const newTokens = await exchangeRefreshToken(refreshToken);

  setAuth(newTokens);
};

export const getCurrentAdmin = async () => {
  const { accessToken } = getTokens();
  const {
    payload: { id },
  } = parseJWT(accessToken);

  const {
    data: body,
    status,
    statusText,
  } = await api.get<Response<AdminDTO>>(`admins/${id}`);

  if (status >= 400) {
    throw new Error(statusText);
  }

  return body.data;
};

export const getUserRole = async () => {
  const { role } = await getCurrentAdmin();

  return role;
};

export const getUserIdentity = async () => {
  const { id, username } = await getCurrentAdmin();

  return {
    id,
    name: username,
    avatar: '/logo.svg',
  };
};
