import { FC } from 'react';
import { ShowBoolean } from 'src/components/show-boolean';
import { NestedContainer } from 'src/components/nested-container';
import { ShowItem } from 'src/components/show-item';
import { ProductType } from 'src/constants/enums';
import { AdMeta, ProductDTO, SubscriptionMeta } from 'src/dtos';

const Subscription: FC<{ meta: SubscriptionMeta }> = ({ meta }) => (
  <NestedContainer>
    <ShowItem title="User Feed Search Access">
      <ShowBoolean value={meta.userFeedSearchAccess} />
    </ShowItem>

    <ShowItem title="Matches Per Day">{meta.matchesPerDayLimit}</ShowItem>

    <ShowItem title="Emergency Call Access">
      <ShowBoolean value={meta.emergencyCallAccess} />
    </ShowItem>

    <ShowItem title="Likes Limit">
      <ShowBoolean value={meta.likesLimit} />
    </ShowItem>
  </NestedContainer>
);

const Ad: FC<{ meta: AdMeta }> = ({ meta }) => (
  <NestedContainer>
    <ShowItem title="Days Count">{meta.daysCount}</ShowItem>
  </NestedContainer>
);

const Unknown: FC = () => <div>Unknown</div>;

export const MetaShow: FC<ProductDTO> = ({ type, meta }) => {
  switch (type) {
    case ProductType.SUBSCRIPTION:
      return <Subscription meta={meta} />;
    case ProductType.AD:
      return <Ad meta={meta} />;
    default:
      return <Unknown />;
  }
};
