import type { AuthProvider } from '@pankod/refine-core';
import {
  checkAuth,
  getUserIdentity,
  getUserRole,
  signin,
} from 'src/services/auth.service';
import { removeAuth } from 'src/stores/auth.store';

export const authProvider: AuthProvider = {
  login({ username, password }) {
    return signin(username, password);
  },

  async logout() {
    removeAuth();
  },

  async checkError(error) {
    const status = error?.statusCode || error.response.status;

    if (status === 401) {
      throw new Error('Unauthorized');
    }
  },

  checkAuth() {
    return checkAuth();
  },

  getPermissions() {
    return getUserRole();
  },

  getUserIdentity() {
    return getUserIdentity();
  },
};
