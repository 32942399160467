/*
 * more than 8 chars
 * at least one number
 * at least one special character
 */
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

export const WRONG_PASSWORD_MESSAGE = (
  <div>
    <div>more than 8 chars</div>
    <div>at least one number</div>
    <div>at least one special character</div>
  </div>
);

/*
 * only letters and numbers
 */
export const USERNAME_REGEX = /^\w+$/;

export const WRONG_USERNAME_MESSAGE = (
  <div>
    <div>only letters and numbers</div>
  </div>
);

/*
 * only letters and space
 */
export const LETTERS_AND_SPACE_REGEX = /^([a-zA-Z]+)( [a-zA-Z]+)*$/;

export const WRONG_LETTERS_AND_SPACE_MESSAGE = (
  <div>
    <div>only letters and space</div>
  </div>
);

/*
 * only numbers from 10 to 16
 */
export const PHONE_REGEX = /^[0-9]{10,16}$/;

export const PHONE_MESSAGE = (
  <div>
    <div>only numbers from 10 to 16</div>
  </div>
);

export const MOBILE_PHONE_REGEX = /^\+[0-9]{12,16}$/;

export const MOBILE_PHONE_MESSAGE = (
  <div>
    <div>+ and numbers from 12 to 16</div>
  </div>
);
