import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { Show, Tag, DateField } from '@pankod/refine-antd';

import { ActivityDTO } from 'src/dtos';
import { ShowItem } from 'src/components/show-item';

export const ActivityShow: FC = () => {
  const { queryResult } = useShow<ActivityDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="Name">{record?.name}</ShowItem>

      <ShowItem title="Icon Src">
        <Tag>+{record?.iconSrc}</Tag>
      </ShowItem>

      <ShowItem title="Active">
        <Tag>{record?.active ? 'Yes' : 'No'}</Tag>
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
