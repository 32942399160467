import { FC } from 'react';
import { useShow } from '@pankod/refine-core';
import { DateField, Empty, Show } from '@pankod/refine-antd';

import { ShowItem } from 'src/components/show-item';
import { ReferenceOne } from 'src/components/reference-one';
import { Status } from 'src/components/status';
import { ChangeStatusButton } from './change-status/change-status.button';
import { UserAppealDTO } from 'src/dtos';

export const UserAppealShow: FC = () => {
  const { queryResult } = useShow<UserAppealDTO>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          {record && <ChangeStatusButton recordItemId={record?.id} />}
          {defaultButtons}
        </>
      )}
    >
      <ShowItem title="ID">{record?.id}</ShowItem>

      <ShowItem title="User">
        {!record?.user && <Empty />}
        {record?.user && (
          <ReferenceOne
            resource="users"
            id={record.user.id}
            label={record.user.username}
          />
        )}
      </ShowItem>

      <ShowItem title="Original User Id">{record?.originalUserId}</ShowItem>

      <ShowItem title="Email">{record?.email}</ShowItem>

      <ShowItem title="Message">{record?.message}</ShowItem>

      <ShowItem title="Status">
        {record?.status && <Status value={record.status} />}
      </ShowItem>

      <ShowItem title="Admin Comment">
        {!record?.adminComment && <Empty />}
        {record?.adminComment}
      </ShowItem>

      <ShowItem title="Updated At">
        <DateField format="LLL" value={record?.updatedAt} />
      </ShowItem>

      <ShowItem title="Created At">
        <DateField format="LLL" value={record?.createdAt} />
      </ShowItem>
    </Show>
  );
};
