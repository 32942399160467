import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import type { HttpError } from '@pankod/refine-core';
import { getAuth } from 'src/stores/auth.store';
import { API_URL } from 'src/constants/config';

export interface Response<D> {
  success: boolean;
  data: D;
  meta: null;
  messages: string[];
  error: null;
}

export const isNotOk = (status: number) => {
  return status >= 400;
};

export const bootstrap = (axios: AxiosInstance = Axios.create()) => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const customError: HttpError = {
        ...error,
        message: error.response?.data?.message,
        statusCode: error.response?.status,
      };

      return Promise.reject(customError);
    },
  );

  axios.interceptors.request.use((req: AxiosRequestConfig) => {
    const tokens = getAuth();
    if (!tokens) return req;

    const { accessToken } = tokens;

    if (req.headers) {
      req.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      req.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return req;
  });

  return axios;
};

export const api = bootstrap(Axios.create({ baseURL: API_URL }));
