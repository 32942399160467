import { FC, useState } from 'react';
import { Button, Form, Modal, notification } from '@pankod/refine-antd';
import { LockOutlined } from '@ant-design/icons';
import { api, isNotOk } from 'src/services/http.service';
import { PasswordInput } from 'src/components/password-input';

interface Props {
  recordItemId: string;
  hideText?: boolean;
  size?: 'small';
}

export const SetPasswordButton: FC<Props> = ({
  hideText,
  size,
  recordItemId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const clear = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const handleFinish = async ({ password }: { password: string }) => {
    const { status } = await api.put(`admins/${recordItemId}/password`, {
      password,
    });

    if (isNotOk(status)) {
      return notification.error({
        message: 'Error',
        description: 'During password set',
      });
    }

    notification.success({ message: 'Success', description: 'Password set' });

    clear();
  };

  return (
    <>
      <Button size={size} icon={<LockOutlined />} onClick={showModal}>
        {!hideText && 'Set password'}
      </Button>

      <Modal
        title="Set password"
        open={isModalVisible}
        okText="Submit"
        onOk={handleOk}
        onCancel={clear}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true }]}
          >
            <PasswordInput />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm"
            dependencies={['password']}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'the two passwords that you entered do not match!',
                  );
                },
              }),
            ]}
          >
            <PasswordInput />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
