import {
  DateField,
  FilterDropdownProps,
  SelectProps,
  Table,
} from '@pankod/refine-antd';
import { ReactNode } from 'react';
import { ShowBoolean } from 'src/components/show-boolean';
import { Status } from 'src/components/status';
import {
  booleanDropdown,
  dateFilterDropdown,
  enumFilterDropdown,
  FilterContext,
  filterDropdownIcon,
  relationSelectDropdown,
  textDropdown,
} from './filter-dropdown.utils';
import { camelCaseToLabel } from './text.utils';

const { Column } = Table;

interface ColumnOptions<V, R> {
  field: string;
  title?: string;
  sorter?: boolean;
  filterDropdown?: (props: FilterDropdownProps) => ReactNode;
  filterIcon?: (filtered: boolean) => ReactNode;
  render?: (value: V, record: R, index: number) => ReactNode;
  defaultRender?: (value: V, record: R, index: number) => ReactNode;
}

const column = <V, R>({
  field,
  title,
  sorter,
  filterDropdown,
  filterIcon,
  render,
  defaultRender,
}: ColumnOptions<V, R>) => (
  <Column
    dataIndex={field}
    title={title ?? camelCaseToLabel(field)}
    filterDropdown={filterDropdown}
    filterIcon={filterIcon}
    render={render ?? defaultRender}
    sorter={sorter ?? true}
  />
);

interface Options<V, R> extends ColumnOptions<V, R> {
  filterContext: FilterContext;
}

interface IdOptions<V, R> extends Omit<Options<V, R>, 'field'> {
  field?: string;
}

export const idColumn = <V, R>({
  filterContext,
  field = 'id',
  title = 'ID',
  ...rest
}: IdOptions<V, R>) => {
  const filterDropdown = textDropdown(filterContext, field, 'eq');
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    title,
    filterDropdown,
    filterIcon,
    ...rest,
  });
};

export const textColumn = <R,>({
  filterContext,
  field,
  ...rest
}: Options<string, R>) => {
  const filterDropdown = textDropdown(filterContext, field);
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    filterDropdown,
    filterIcon,
    ...rest,
  });
};

interface EnumOptions<E extends Record<string, string>, R>
  extends Options<E[keyof E], R> {
  enum: E;
  formatter?: (value: string) => string;
}

export const enumColumn = <E extends Record<string, string>, R>({
  filterContext,
  field,
  formatter,
  ...rest
}: EnumOptions<E, R>) => {
  const filterDropdown = enumFilterDropdown<E>(
    filterContext,
    field,
    rest.enum,
    formatter,
  );
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    filterDropdown,
    filterIcon,
    defaultRender: (value: string) => <Status value={value} />,
    ...rest,
  });
};

interface RelationOptions<V, R> extends Omit<Options<V, R>, 'render'> {
  render: (value: V, record: R, index: number) => ReactNode;
  selectProps: SelectProps<{ value: string; label: string }>;
}

export const relationColumn = <V, R>({
  filterContext,
  field,
  selectProps,
  title,
  ...rest
}: RelationOptions<V, R>) => {
  const filterDropdown = relationSelectDropdown(
    filterContext,
    field,
    selectProps,
  );
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    filterDropdown,
    filterIcon,
    title: title ?? camelCaseToLabel(field.replace(/id/i, '')),
    ...rest,
  });
};

export const dateColumn = <R,>({
  filterContext,
  field,
  ...rest
}: Options<string, R>) => {
  const filterDropdown = dateFilterDropdown(filterContext, field);
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    filterDropdown,
    filterIcon,
    defaultRender: (value: string) => <DateField format="LLL" value={value} />,
    ...rest,
  });
};

export const booleanColumn = <R,>({
  filterContext,
  field,
  ...rest
}: Options<boolean, R>) => {
  const filterDropdown = booleanDropdown(filterContext, field);
  const filterIcon = filterDropdownIcon(filterContext, field);

  return column({
    field,
    filterDropdown,
    filterIcon,
    defaultRender: (value: boolean) => <ShowBoolean value={value} />,
    ...rest,
  });
};

const MAX_CHARACTERS = 60;

export const trimBigText = (value: string) => {
  if (value.length <= MAX_CHARACTERS) {
    return value;
  }

  return `${value.slice(0, MAX_CHARACTERS)}...`;
};
