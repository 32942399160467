import { FC } from 'react';

import {
  List,
  Table,
  Space,
  ShowButton,
  Row,
  Col,
  DeleteButton,
} from '@pankod/refine-antd';

import { AdDTO } from 'src/dtos';
import { ReferenceOne } from 'src/components/reference-one';
import { AdStatus } from 'src/constants/enums';
import { useTable } from 'src/hooks/use-table';
import {
  dateColumn,
  enumColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';

export const AdList: FC = () => {
  const { tableProps, filterContext } = useTable<AdDTO>();

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List>
          <Table {...tableProps}>
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'title' })}
            {idColumn({
              filterContext,
              field: 'creatorId',
              title: 'Creator ID',
              render: (_, record: AdDTO) => (
                <ReferenceOne
                  resource="users"
                  id={record.creator.id}
                  label={record.creator.username}
                />
              ),
            })}
            {enumColumn({ filterContext, field: 'status', enum: AdStatus })}
            {dateColumn({ filterContext, field: 'boostedAt' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<AdDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
