import { FC } from 'react';
import { Card } from '@pankod/refine-antd';
import styles from './styles.module.css';

const MANUAL_LINK =
  'https://docs.google.com/document/d/1djr7tqzy8Gv7xXC2GVNNRUe3bSRBy5UheiFPzLNCLL8/edit';
const PRIVACY_POLICY_LINK =
  'https://docs.google.com/document/d/103WTRpx7edAP2VxnNT5JLaU-ASSKsrZrr9rVy0cBF-k/edit';
const TERMS_OF_USE_LINK =
  'https://docs.google.com/document/d/1MW62LDNDpJ9iFW_ozCtmNUqQHT5wd7wp6uVCUhqJ0EI/edit';

const links = [
  { link: MANUAL_LINK, title: 'Manual' },
  { link: PRIVACY_POLICY_LINK, title: 'Privacy Policy' },
  { link: TERMS_OF_USE_LINK, title: 'Terms Of Use' },
];

export const DashboardPage: FC = () => (
  <div>
    <Card title="Links:">
      {links.map(({ link, title }) => (
        <Card.Grid className={styles['grid']}>
          <a
            className={styles['link']}
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >
            {title}
          </a>
        </Card.Grid>
      ))}
    </Card>
  </div>
);
