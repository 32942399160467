import { notification, RcFile, Upload, UploadFile } from '@pankod/refine-antd';
import { FC } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { rcFileToImageValue, ImageValueDTO } from 'src/utils/image.utils';

interface Props {
  value?: ImageValueDTO[];
  onChange?: (images: ImageValueDTO[]) => void;
  maxCount?: number;
}

export const MultipleImageInput: FC<Props> = ({
  value,
  onChange,
  maxCount,
}) => {
  const beforeUpload = (rcFile: RcFile) => {
    if (maxCount && value?.length === maxCount) {
      return notification.info({ message: `Reached file limit: ${maxCount}` });
    }

    onChange?.([...(value ?? []), rcFileToImageValue(rcFile)]);

    return false;
  };

  const onRemove = (file: UploadFile) => {
    const newValues = value?.filter(image => image.uid !== file.uid) ?? [];

    onChange?.(newValues);

    return false;
  };

  return (
    <Upload.Dragger
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      fileList={value ?? undefined}
      accept="image/jpeg,image/png"
      multiple={true}
      maxCount={maxCount}
      name="Upload Cover"
      listType="picture"
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload and replace previous
      </p>
    </Upload.Dragger>
  );
};
