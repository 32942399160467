import { defineResource } from 'src/utils/define-resource.utils';
import { SnippetsOutlined } from '@ant-design/icons';

import { UserReportList } from './user-report.list';
import { UserReportShow } from './user-report.show';

export default defineResource({
  name: 'user-reports',
  list: UserReportList,
  show: UserReportShow,
  icon: <SnippetsOutlined />,
  parentName: 'other',
  options: {
    label: 'Reports',
  },
});
