export enum EnumGender {
  MALE = 'male',
  FEMALE = 'female',
  SECRET = 'secret',
}

export const EnumGenderColors = {
  [EnumGender.MALE]: 'blue',
  [EnumGender.FEMALE]: 'pink',
  [EnumGender.SECRET]: 'magenta',
};

export enum OnboardingStepEnum {
  SIGNUP = 'signup',
  PROFILE = 'profile',
  FINISHED = 'finished',
}

export const OnboardingStepColors = {
  [OnboardingStepEnum.SIGNUP]: 'error',
  [OnboardingStepEnum.PROFILE]: 'processing',
  [OnboardingStepEnum.FINISHED]: 'success',
};

export enum RequestTypeEnum {
  NEED_HELP = 'Need help',
  SAY_THANK_YOU = 'Say thank you',
  CAUGHT_BUG = 'Caught a bug',
  NEED_MORE_FUNCTIONS = 'Need more functions',
  OTHER = 'Other',
}

export const RequestTypeColors = {
  [RequestTypeEnum.NEED_HELP]: 'warn',
  [RequestTypeEnum.SAY_THANK_YOU]: 'success',
  [RequestTypeEnum.CAUGHT_BUG]: 'error',
  [RequestTypeEnum.NEED_MORE_FUNCTIONS]: 'magenta',
  [RequestTypeEnum.OTHER]: 'cyan',
};

export enum UserReportStatus {
  WAITING = 'waiting',
  RESOLVED = 'resolved',
}

export enum UserReportType {
  COPYRIGHT = 'report_reason_copyright',
  MORAL = 'report_reason_moral',
  PRIVACY = 'report_reason_privacy',
  ADS = 'report_reason_ads',
  SPAM = 'report_reason_spam',
}

export enum UserAppealStatus {
  WAITING = 'waiting',
  RESOLVED = 'resolved',
}

export enum UserProfileVisibleEnum {
  EVERYONE = 'everyone',
  FRIENDS = 'friends',
}

export enum LanguageEnum {
  EN = 'en',
  ES = 'es',
  RU = 'ru',
  UK = 'uk',
}

export enum UnitsEnum {
  KM = 'km',
  ML = 'ml',
}

export enum NotificationSettingsKeys {
  SHOW_NEW_MESSAGES = 'messages',
  SHOW_EVENT_INVITATIONS = 'invitations',
  SHOW_UPCOMING_EVENTS = 'events',
  SHOW_NEW_LIKES = 'likes',
  SHOW_NEW_MATCH = 'match',
}

export enum SocialLinksKeys {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  STRAVA = 'strava',
}

export enum UserActivityLevelEnum {
  BEGINNER = 'beginner',
  MEDIUM = 'medium',
  ADVANCED = 'advanced',
}

export enum EmergencyCallStatus {
  WAITING = 'waiting',
  RUNNING = 'running',
  SECURITY_CODE_WAITING = 'securityCodeWaiting',
  ALARM_STARTED = 'alarmStarted',
  CANCELED = 'canceled',
  CANCELED_BY_ADMIN = 'canceledByAdmin',
  FINISHED = 'finished',
}

export enum EventType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum EventStatus {
  WAITING = 'waiting',
  RUNNING = 'running',
  CANCELED = 'canceled',
  CANCELED_BY_ADMIN = 'canceledByAdmin',
  FINISHED = 'finished',
}

export enum AdType {
  BANNER = 'banner',
  EVENT = 'event',
}

export enum AdStatus {
  WAITING_FOR_PAYMENT = 'waitingForPayment',
  WAITING = 'waiting',
  RUNNING = 'running',
  FINISHED = 'finished',
}

export enum ProductType {
  AD = 'ad',
  SUBSCRIPTION = 'subscription',
}

export enum ProductPlatform {
  PLAY_STORE = 'play_store',
  APP_STORE = 'app_store',
}

export enum PaymentStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export enum PaymentProvider {
  GOOGLE_PLAY = 'googlePlay',
  APP_STORE = 'appStore',
}

export enum PaymentEnvironment {
  PRODUCTION = 'production',
  SANDBOX = 'sandbox',
}

export enum PaymentHandleStatus {
  WAITING = 'waiting',
  RUNNING = 'running',
  FINISHED = 'finished',
}

export enum UserColorModeEnum {
  WHITE = 'white',
  DARK = 'dark',
}

export enum RegisterMethod {
  EMAIL_AND_PASSWORD = 'emailAndPassword',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export enum NotificationProviderName {
  PUSH = 'push',
  WS = 'ws',
}

export enum AdminRole {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export enum Events {
  /*
   * api Payment events service
   */
  PRODUCT_PURCHASED = 'product.purchased',

  /*
   * api User subscription service
   */
  SUBSCRIPTION_STARTED = 'subscription.started',
  SUBSCRIPTION_FINISHED = 'subscription.finished',

  /*
   * api Ad service
   */
  AD_STARTED = 'ad.started',
  AD_UPDATED = 'ad.updated',
  AD_FINISHED = 'ad.finished',

  /*
   * api User service
   */
  USER_EMAIL_VERIFIED = 'user.email.verified',
  USER_COMEBACK_REMAINDER = 'user.comebackRemainder',

  /*
   * api User like service
   */
  USER_LIKE_CREATED = 'userLike.created',
  USER_LIKE_DELETED = 'userLike.deleted',
  USER_MATCH_CREATED = 'userMatch.created',
  USER_MATCH_DELETED = 'userMatch.deleted',

  /*
   * common service Connection events
   */
  USER_ONLINE = 'user.online',
  USER_OFFLINE = 'user.offline',

  /*
   * api Admin User service
   */
  USER_BAN_CREATED = 'user.ban.created',
  USER_BAN_DELETED = 'user.ban.deleted',

  /*
   * api Event service
   */
  EVENT_UPDATED = 'event.updated',
  EVENT_DELETED = 'event.deleted',
  EVENT_FINISHED = 'event.finished',
  EVENT_BEFORE_STARTED = 'event.beforeStarted',
  EVENT_STARTED = 'event.started',
  EVENT_INVITATION_ADDED = 'event.invitation.added',

  /*
   * api Emergency call service
   */
  EMERGENCY_CALL_BEFORE_STARTED = 'emergencyCall.beforeStarted',

  /*
   * ws WS gateway
   */
  CONNECTION_SUCCESS = 'connection.success',
  CONNECTION_FAILED = 'connection.failed',

  /*
   * api Chat message service
   */
  MESSAGE_CREATED = 'chat.message.created',
  MESSAGE_DELETED = 'chat.message.deleted',
  MESSAGE_UPDATED = 'chat.message.updated',

  /*
   * api User block service
   */
  USER_BLOCK_CREATED = 'userBlock.created',
  USER_BLOCK_DELETED = 'userBlock.deleted',
}
