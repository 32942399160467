import { FC, useState } from 'react';

import {
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  DeleteButton,
  CreateButton,
  Button,
} from '@pankod/refine-antd';

import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';

import { CountryDTO } from 'src/dtos';
import { useTable } from 'src/hooks/use-table';
import {
  booleanColumn,
  dateColumn,
  idColumn,
  textColumn,
} from 'src/utils/list.utils';
import { changeActive } from 'src/services/country.service';
import { useUpdateMany } from '@pankod/refine-core';

// add list refresh
export const CountryList: FC = () => {
  const { tableProps, filterContext, setCurrent } = useTable<CountryDTO>();
  const { mutateAsync } = useUpdateMany<CountryDTO>();
  const [selected, setSelected] = useState<CountryDTO[]>([]);

  const changeActiveStatus = async (to: boolean) => {
    if (!selected.length) return;

    const ids = selected.map(country => country.id);

    await changeActive(ids, to);
    await mutateAsync({
      resource: 'countries',
      ids,
      values: selected,
    });

    // to prevent bug when you on empty second page
    setCurrent(1);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List
          headerButtons={
            <Space>
              <Button onClick={() => changeActiveStatus(true)}>
                <PlusSquareOutlined /> Active
              </Button>
              <Button onClick={() => changeActiveStatus(false)}>
                <MinusSquareOutlined /> Disable
              </Button>
              <CreateButton />
            </Space>
          }
        >
          <Table
            rowSelection={{
              type: 'checkbox',
              onChange: (ids, rows) => setSelected(rows),
              selections: [Table.SELECTION_INVERT, Table.SELECTION_NONE],
            }}
            {...tableProps}
          >
            {idColumn({ filterContext })}
            {textColumn({ filterContext, field: 'name' })}
            {textColumn({ filterContext, field: 'code' })}
            {textColumn({ filterContext, field: 'phoneCode' })}
            {booleanColumn({ filterContext, field: 'active' })}
            {dateColumn({ filterContext, field: 'updatedAt' })}
            {dateColumn({ filterContext, field: 'createdAt' })}

            <Table.Column<CountryDTO>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <ShowButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
