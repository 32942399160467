import { defineResource } from 'src/utils/define-resource.utils';
import { FormOutlined } from '@ant-design/icons';

import { UserRequestList } from './user-request.list';
import { UserRequestShow } from './user-request.show';

export default defineResource({
  name: 'users-requests',
  list: UserRequestList,
  show: UserRequestShow,
  icon: <FormOutlined />,
  parentName: 'other',
  options: {
    label: 'Feedback',
  },
});
