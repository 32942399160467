import { FC } from 'react';
import {
  Select as SelectWithBrokenTypes,
  SelectProps,
} from '@pankod/refine-antd';
import styles from './styles.module.css';
import { clsx } from 'src/utils/clsx.utils';

export const TableSelect: FC<
  SelectProps<{ value: string; label: string }>
> = props => (
  <SelectWithBrokenTypes
    {...props}
    className={clsx(styles['table-select'], props.className)}
  />
);
